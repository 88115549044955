import React from "react";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import video1 from './video/v1.mp4';
import Navbar from "./Navbar";
import Footer from "./Footer";



const How_Works = () => {
    return (
        <>

         {/* navbar */}
          <Navbar />


            <div className=" container-fluid main_works" style={{  paddingTop: '3rem' }}>
                <div className="container">
                    <h4 >How It works</h4>
                    <h6 className="pb-4 text-center">It takes just minutes to get started.</h6>

                    <hr />

                    <div className="row scroll_main ">

                        <div className="col-lg-5 col-md-12 col-sm-12 border border-0 " style={{ borderRadius: '20px', height: '700px', marginTop: '4rem' }} >
                            <div className="scroll scroll_1">
                                <h6><span style={{ fontSize: '25px' }}>1.</span> Profile Management</h6>
                                <p className="pt-2"><ArrowCircleRightIcon fontSize="medium" /> How to Create Multiple Digital Business Profiles?</p>
                                <p><ArrowCircleRightIcon fontSize="medium" /> How to Manage Multiple Digital Profiles on the Scube App?</p>


                                <h6><span style={{ fontSize: '25px' }}>2.</span> Card Linking Methods</h6>
                                <p className="pt-2"><ArrowCircleRightIcon fontSize="medium" /> How to Link Your Scube Smart Card?</p>
                                <p><ArrowCircleRightIcon fontSize="medium" /> How to Link a New Smart Card to the Scube App?</p>


                                <h6><span style={{ fontSize: '23px' }}>3.</span> Securing Your Scube Card</h6>
                                <p className="pt-2"><ArrowCircleRightIcon fontSize="medium" /> How to Disable Your Smart Business Card?</p>
                                <p><ArrowCircleRightIcon fontSize="medium" /> How to Delete Your Smart Business Card?</p>

                                <h6><span style={{ fontSize: '23px' }}>4.</span> Card Functionality Tutoria</h6>
                                <p className="pt-2"><ArrowCircleRightIcon fontSize="medium" /> How to Share Your Scube Profile Via NFC on Android?</p>
                                <p><ArrowCircleRightIcon fontSize="medium" /> How to Share Your Scube Profile Via NFC on iOS?</p>
                                <p> <ArrowCircleRightIcon fontSize="medium" /> How to Scan QR Code for Scube Cards on Android?</p>
                                <p><ArrowCircleRightIcon fontSize="medium" /> How to Scan QR Code for Scube Cards on iOS?</p>

                                <h6><span style={{ fontSize: '23px' }}>5.</span> Effortless Sharing</h6>
                                <p className="pt-2"><ArrowCircleRightIcon fontSize="medium" /> How to Effortlessly Share Your Scube Profile?</p>
                                <p><ArrowCircleRightIcon fontSize="medium" />How to Share Your Scube Profile Via Digital QR Code?</p>
                            </div>

                        </div>

                        <div className="col-lg-7 col-md-12 col-sm-12 mt-3">

                            {/* first content -1 */}
                            <div className="second_works ms-4">
                                <h6 style={{ fontSize: '26px' }}>How to Create Multiple Digital Business Profiles?</h6>
                                <p className="pb-5 pt-3">Creating multiple digital business profiles in the Scube App is a streamlined process designed for efficiency. Follow these simple steps to establish distinct profiles tailored to your various business endeavours.</p>

                                {/* first video--- */}

                                <video width="100%" autoPlay loop height="400" >
                                    <source src={video1} type="video/mp4" />
                                    first video
                                </video>
                            </div>



                            {/* first content -2 */}

                            <div className="second_works ms-4" style={{ paddingTop: '3rem' }}>
                                <h6 style={{ fontSize: '26px' }}>How to Manage Multiple Digital Profiles on the Scube App?</h6>
                                <p className="pb-5 pt-3"> Managing multiple digital profiles on the Scube App ensures seamless organisation and accessibility. Follow these straightforward guidelines to oversee and navigate your various digital identities within the app.</p>

                                {/* first video--- */}

                                <video width="100%" autoPlay loop height="400" >
                                    <source src={video1} type="video/mp4" />
                                    first video
                                </video>
                            </div>



                            {/* second content -1*/}

                            <div className="second_works ms-4" style={{ paddingTop: '3rem' }}>
                                <h6 style={{ fontSize: '26px' }}>How to Link Your Scube Smart Card?</h6>
                                <p className="pb-5 pt-3">Seamlessly connect your Scube Smart Business Card to your device using NFC technology & QR Code for enhanced accessibility and streamlined functionality. Follow these simple steps to establish a secure and efficient link between your card and your device.</p>

                                {/* video--- */}

                                <video width="100%" autoPlay loop height="400" >
                                    <source src={video1} type="video/mp4" />
                                    First Video
                                </video>
                            </div>

                            {/* second content -2*/}

                            <div className="second_works ms-4" style={{ paddingTop: '3rem' }}>
                                <h6 style={{ fontSize: '26px' }}>How to Link a New Smart Card to the Scube App?</h6>
                                <p className="pb-5 pt-3"> Expand your digital profile collection by adding a new smart card to the Scube App, enhancing versatility and functionality. Discover these simple steps to link a new smart card to your Scube App, enabling seamless integration and access to all your digital profiles in one place.</p>

                                {/* video--- */}

                                <video width="100%" autoPlay loop height="400" >
                                    <source src={video1} type="video/mp4" />
                                    Second video
                                </video>
                            </div>




                            {/* third content -1*/}

                            <div className="second_works ms-4" style={{ paddingTop: '3rem' }}>
                                <h6 style={{ fontSize: '26px' }}>How to Disable Your Smart Business Card?</h6>
                                <p className="pb-5 pt-3"> Ensure the security of your digital identity by disabling your Smart Business Card in case of loss or theft, safeguarding your information. Follow these steps to disable your Smart Business Card.</p>

                                {/* video--- */}

                                <video width="100%" autoPlay loop height="400">
                                    <source src={video1} type="video/mp4" />
                                    Third video
                                </video>
                            </div>



                            {/* third content -2*/}

                            <div className="second_works ms-4" style={{ paddingTop: '3rem' }}>
                                <h6 style={{ fontSize: '26px' }}>How to Delete Your Smart Business Card?</h6>
                                <p className="pb-5 pt-3"> Streamline your digital identity management by permanently removing your Smart Business Card Profile from the Scube App, and decluttering your digital profiles. Learn how to delete your Smart Business Card from the Scube App, enhancing efficiency and clarity in your digital profile collection.</p>

                                {/* video--- */}

                                <video width="100%" autoPlay loop height="400" >
                                    <source src={video1} type="video/mp4" />
                                    first video
                                </video>
                            </div>




                            {/* FOUR content -1*/}

                            <div className="second_works ms-4" style={{ paddingTop: '3rem' }}>
                                <h6 style={{ fontSize: '26px' }}>How to Share Your Scube Profile Via NFC on Android?</h6>
                                <p className="pb-5 pt-3"> Exchange digital profiles with others using NFC technology on Android devices, facilitating quick and convenient sharing of your Scube profile. Follow these instructions to share your Scube profile via NFC on Android, enhancing connectivity in your professional network.</p>

                                {/* video--- */}

                                <video width="100%" autoPlay loop height="400">
                                    <source src={video1} type="video/mp4" />
                                    first video
                                </video>
                            </div>



                            {/* FOUR content -2*/}

                            <div className="second_works ms-4" style={{ paddingTop: '3rem' }}>
                                <h6 style={{ fontSize: '26px' }}>How to Share Your Scube Profile Via NFC on iOS?</h6>
                                <p className="pb-5 pt-3"> Exchange digital profiles with others using NFC technology on iOS devices, facilitating quick and convenient sharing of your Scube profile. Follow these instructions to share your Scube profile via NFC on iOS, enhancing connectivity in your professional network.</p>

                                {/* video--- */}

                                <video width="100%" autoPlay loop height="400">
                                    <source src={video1} type="video/mp4" />
                                    first video
                                </video>
                            </div>


                            {/* FOUR content -3*/}

                            <div className="second_works ms-4" style={{ paddingTop: '3rem' }}>
                                <h6 style={{ fontSize: '26px' }}>How to Scan QR Code for Scube Cards on Android?</h6>
                                <p className="pb-5 pt-3"> Streamline the process of saving contact details from your Scube card by using a QR code scanner on your Android device. This method ensures a quick and hassle-free way to manage and retrieve information from your Scube card, enhancing your overall user experience..</p>

                                {/* video--- */}

                                <video width="100%" autoPlay loop height="400">
                                    <source src={video1} type="video/mp4" />
                                    first video
                                </video>
                            </div>


                            {/* FOUR content -4*/}

                            <div className="second_works ms-4" style={{ paddingTop: '3rem' }}>
                                <h6 style={{ fontSize: '26px' }}>How to Scan QR Code for Scube Cards on iOS?</h6>
                                <p className="pb-5 pt-3"> Streamline the process of saving contact details from your Scube card by using a QR code scanner on your iOS device. This method ensures a quick and hassle-free way to manage and retrieve information from your Scube card, enhancing your overall user experience.</p>

                                {/* video--- */}

                                <video width="100%" autoPlay loop height="400">
                                    <source src={video1} type="video/mp4" />
                                    first video
                                </video>
                            </div>




                            {/* five content -1*/}

                            <div className="second_works ms-4" style={{ paddingTop: '3rem' }}>
                                <h6 style={{ fontSize: '26px' }}>How to Effortlessly Share Your Scube Profile?</h6>
                                <p className="pb-5 pt-3"> Enhance networking and collaboration by sharing your Scube profile with others, fostering connections and opportunities. Learn how to share your Scube profile with others on external domains, facilitating communication and interaction.</p>

                                {/* video--- */}

                                <video width="100%" autoPlay loop height="400" >
                                    <source src={video1} type="video/mp4" />
                                    first video
                                </video>
                            </div>

                            {/* five content -2*/}

                            <div className="second_works ms-4" style={{ paddingTop: '3rem' }}>
                                <h6 style={{ fontSize: '26px' }}>How to Share Your Scube Profile Via Digital QR Code?</h6>
                                <p className="pb-5 pt-3"> Exchange digital identities with others using digital QR codes, providing a convenient and secure sharing method. Discover how to share your Scube profile via digital QR codes, ensuring efficient networking and collaboration while maintaining data security and privacy.</p>

                                {/* video--- */}

                                <video width="100%" autoPlay loop height="400">
                                    <source src={video1} type="video/mp4" />
                                    first video
                                </video>
                            </div>


                        </div>
                    </div>
                </div>
            </div>


            {/* footer */}
           <div className="footer_works">
           <Footer />
           </div>
            
        </>
    )

}


export default How_Works;