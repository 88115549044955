import React, { useEffect } from 'react';
import Navbar from './Navbar';

import Footer from './Footer';

import video from './video/v2.mp4'

import AOS from 'aos';
import 'aos/dist/aos.css';


const Affliate = () => {

  useEffect(() => {
    AOS.init({
      duration: 1200,  // Animation duration
      once: true,  // Whether animation should happen only once
    });
  }, []);


  return (
    <>


      {/* Navbar------------------------------------ */}
      <div className='navbar'>
        <Navbar />
      </div>



      <div className="Main_About_video container-fluid p-0 h-auto  ">


        <video width="100%" autoPlay loop className="back-video">
          <source src={video} type="video/mp4" />
        </video>

        {/* <div className="content ">
          <h1>Growing your network</h1>

        </div> */}


      </div>


      {/* add marquee tag here */}
      {/* <div className='container-fluid main_affliate pt-5' style={{background:'lightgrey',paddingBottom:'7rem'}}>

        <div className='container row' style={{ width: '100%', margin: 'auto' }}>


          <h4 style={{ color: '#5C5350', paddingBottom: '3rem' }}>Features</h4>
        
        </div>
       </div> */}


      {/* box content-------------------------------------- */}

      <div className='box_content main_affliate pt-5' style={{ background: "linear-gradient(to right, #ffffff, white)" }}>
        <h4 style={{ color: '#5C5350', paddingBottom: '1rem' }} data-aos="fade-up">Why Join Us?</h4>

        <div className='about_content' data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">
          <h5 >01. Monetize Your Influence & grow your revenue stream.</h5>
          <hr style={{ marginTop: '1rem' }} />

          <h5 >02. High Conversion Rates ensuring maximum earnings potential.</h5>
          <hr style={{ marginTop: '1rem' }} />

          <h5 >03. Easily integrate TapOnn affiliate links and promotions into your content.</h5>
          <hr style={{ marginTop: '1rem' }} />

          <h5 >04. TapOnn offers a diverse range of smart products, appealing to a wide audience.</h5>
          <hr style={{ marginTop: '1rem' }} />

          <h5 >05. Receive dedicated support from our team to assist you in maximizing your affiliate earnings.</h5>
          <hr style={{ marginTop: '1rem' }} />

          <h5 >06. Gain exclusive access to our smart community and events, expanding your network and knowledge.</h5>
          <hr style={{ marginTop: '1rem' }} />

          <h5 >07. Be the first to experience and promote TapOnn's latest product launches, enhancing your credibility as an affiliate partner.</h5>
          <hr style={{ marginTop: '1rem' }} />


        </div>

        <div className='btn' style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem', paddingBottom: '4rem' }}>
          <button type="submit" style={{ padding: '10px 30px', fontSize: '22px', borderRadius: '30px' }} className="btn btn-lg btn-dark ">Submit</button>
        </div>
      </div>



      {/*------------------- accordin start------------------------------------- */}

      <div className='box_content main_affliate affliate_acc  '  style={{ paddingBottom: '8rem',paddingTop:'4rem' }}>
        <h4 style={{ color: '#132b43', paddingBottom: '2rem' }} >FAQ</h4>

        <div className='about_content2' data-aos="fade-up"
        data-aos-duration="2000">

          {/* accordian */}

          <div className="accordion ac2" id="accordionExample"  >
            <div className="accordion-item" >
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne" style={{  fontWeight: 'bold', fontFamily: 'revert-layer', fontSize: '20px' }}
                >
                  1.) Is there a cost to join your affiliate program?
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample" style={{fontSize:'18px'}}
              >
                <div className="accordion-body">

                  Not at all, This affiliate program is completely free.
                </div>
              </div>
            </div>


            {/* second */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo" style={{ fontWeight: 'bold', fontFamily: 'revert-layer', fontSize: '20px' }}
                >
                  2.) Which products can i promote as an affiliate?
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample" style={{fontSize:'18px'}}
              >
                <div className="accordion-body">
                  All your favourite TapOnn products.
                </div>
              </div>
            </div>



            {/* third */}
            <div className="accordion-item ">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree" style={{ fontWeight: 'bold', fontFamily: 'revert-layer', fontSize: '20px' }}
                >
                  3.) How much can i earn from this program?
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample" style={{fontSize:'18px'}}
              >
                <div className="accordion-body">
                  Infinite, the more referrals you get, the more you earn as commission.
                </div>
              </div>
            </div>


            {/* four */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFour">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour" style={{ fontWeight: 'bold', fontFamily: 'revert-layer', fontSize: '20px' }}
                >
                  4.) How can I track my referrals?
                </button>
              </h2>
              <div
                id="collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample" style={{fontSize:'18px'}}
              >
                <div className="accordion-body">
                  We provide you with a custom dashboard to help you track your referrals. We also provide you with tools & custom links to share with your audience.
                </div>
              </div>
            </div>


            {/* five */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingfive">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="false"
                  aria-controls="collapsefive" style={{ fontWeight: 'bold', fontFamily: 'revert-layer', fontSize: '20px' }}
                >
                  5.) Can I promote your products/services Internationally?
                </button>
              </h2>
              <div
                id="collapsefive"
                className="accordion-collapse collapse"
                aria-labelledby="headingfive"
                data-bs-parent="#accordionExample" style={{fontSize:'18px'}}
              >
                <div className="accordion-body">
                  Yes, we ship Internationally.
                </div>
              </div>
            </div>


            {/* six */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingsix">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsesix"
                  aria-expanded="false"
                  aria-controls="collapsesix" style={{ fontWeight: 'bold', fontFamily: 'revert-layer', fontSize: '20px' }}
                >
                  6.) What happens if a customer I referred returns a product?
                </button>
              </h2>
              <div
                id="collapsesix"
                className="accordion-collapse collapse"
                aria-labelledby="headingsix"
                data-bs-parent="#accordionExample" style={{fontSize:'18px'}}
              >
                <div className="accordion-body">
                  As we are doing the 90 days return policy, so you will receive the commission only after 90 days of the purchase made through you.
                </div>
              </div>
            </div>

            {/* seven */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingseven">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseseven"
                  aria-expanded="false"
                  aria-controls="collapseseven" style={{ fontWeight: 'bold', fontFamily: 'revert-layer', fontSize: '20px' }}
                >
                  7.) Is there a dedicated support team for affiliates if I have questions or issues?
                </button>
              </h2>
              <div
                id="collapseseven"
                className="accordion-collapse collapse"
                aria-labelledby="headingseven"
                data-bs-parent="#accordionExample" style={{fontSize:'18px'}}
              >
                <div className="accordion-body">
                  Yes, we have a 24/7 support team. You can contact us at
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>





      {/* footer */}
      <div className='footer'>
        <Footer />
      </div>
    </>
  )
}

export default Affliate;