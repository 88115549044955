import React from 'react';

import Navbar from './Navbar';

import img2 from './img/stands/img21.png';
import img1 from './img/stands/img22.png';
import img3 from './img/stands/img23.png';

import img4 from './img/stands/s31.png';
import img5 from './img/stands/s32.png';
import img6 from './img/stands/s33.png';

import img8 from './img/stands/s41.png';
import img7 from './img/stands/s43.png';
import img9 from './img/stands/333.png';

import img10 from './img/stands/e1.png';
import img11 from './img/stands/e2.png';
import img12 from './img/stands/e3.png';

import img13 from './img/stands/g1.png';
import img14 from './img/stands/g2.png';
import img15 from './img/stands/g4.png';


import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import Footer from './Footer';

const Stand_Card = () => {
    return (
        <>

            <Navbar />

            <div id='team' className='pb-5 pt-5 main_stand_card text-center'>
                <div className='container'>
                    <h5 class="section-title">Stand Card </h5>
                    <div class="row">
                        {/* <!-- Team member --> */}

                        <div class="col-lg-4 col-sm-12 col-md-6 " style={{marginTop:'5rem'}} >
                            <div class="image-flip" >
                                <div class="mainflip flip-0">
                                    <div class="frontside">
                                        <div class="card" style={{width:'92%'}}>
                                            <div class="card-body text-center">
                                                <p><img class=" img-fluid" src={img1} /></p>
                                                <h6 class="card-title" style={{ fontWeight: 'bold', letterSpacing: '1px',fontSize:'24px' }}>Classy Black - Metal NFC</h6>
                                                <p class="card-text" style={{ fontSize: '19px' }}>Business Visiting Card</p>
                                                <h6 style={{ fontWeight: 'bold', fontSize: '20px' }}>₹ 1999 ( 60% OFF )</h6>
                                                <a href="#" class="btn btn-dark btn-lg mb-2 mt-3" > View </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="backside" style={{width:'92%'}}>
                                        <div class="card" >
                                            <div class="card-body text-center mt-4">
                                                <h4 class="card-title">Classy Black - Metal NFC  </h4>

                                                <h4 class="card-title">  Business Visiting Card</h4>
                                                <p class="card-text p-4" style={{ fontSize: '17px' }}>This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.</p>
                                                

                                                    <div className='social_media pt-2'>
                                                        <a href='https://www.facebook.com/customcliq' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <FacebookIcon style={{ fontSize: 40 }} /></a>
                                                        <a href='https://www.instagram.com/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <InstagramIcon style={{ fontSize: '40', marginLeft: '1rem' }} /> </a>
                                                        <a href='https://wa.me/919054051551' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <WhatsAppIcon style={{ fontSize: '40', marginLeft: '1rem' }} /> </a>
                                                        <a href='https://www.linkedin.com/company/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <LinkedInIcon style={{ fontSize: '40', marginLeft: '1rem' }} />    </a>
                                                   

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-12 col-md-6 " style={{marginTop:'5rem'}}>
                            <div class="image-flip" >
                                <div class="mainflip flip-0">
                                    <div class="frontside">
                                        <div class="card" style={{width:'92%'}}>
                                            <div class="card-body text-center">
                                                <p><img class=" img-fluid" src={img2} /></p>
                                                <h6 class="card-title" style={{ fontWeight: 'bold', letterSpacing: '1px',fontSize:'24px' }}>Classy Black - Metal NFC</h6>
                                                <p class="card-text" style={{ fontSize: '19px' }}>Business Visiting Card</p>
                                                <h6 style={{ fontWeight: 'bold', fontSize: '20px' }}>₹ 1999 ( 60% OFF )</h6>
                                                <a href="#" class="btn btn-dark btn-lg mb-2 mt-3" > View </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="backside" style={{width:'92%'}}>
                                        <div class="card">
                                            <div class="card-body text-center mt-4">
                                                <h4 class="card-title">Classy Black - Metal NFC  </h4>

                                                <h4 class="card-title">  Business Visiting Card</h4>
                                                <p class="card-text p-4" style={{ fontSize: '17px' }}>This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.</p>
                                                

                                                    <div className='social_media pt-2'>
                                                        <a href='https://www.facebook.com/customcliq' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <FacebookIcon style={{ fontSize: 40 }} /></a>
                                                        <a href='https://www.instagram.com/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <InstagramIcon style={{ fontSize: '40', marginLeft: '1rem' }} /> </a>
                                                        <a href='https://wa.me/919054051551' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <WhatsAppIcon style={{ fontSize: '40', marginLeft: '1rem' }} /> </a>
                                                        <a href='https://www.linkedin.com/company/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <LinkedInIcon style={{ fontSize: '40', marginLeft: '1rem' }} />    </a>
                                                   

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="col-lg-4 col-sm-12 col-md-6" style={{marginTop:'5rem'}}>
                            <div class="image-flip" >
                                <div class="mainflip flip-0">
                                    <div class="frontside">
                                        <div class="card" style={{width:'92%'}}>
                                            <div class="card-body text-center">
                                                <p><img class=" img-fluid" src={img3} /></p>
                                                <h6 class="card-title" style={{ fontWeight: 'bold', letterSpacing: '1px',fontSize:'24px' }}>Classy Black - Metal NFC</h6>
                                                <p class="card-text" style={{ fontSize: '19px' }}>Business Visiting Card</p>
                                                <h6 style={{ fontWeight: 'bold', fontSize: '20px' }}>₹ 1999 ( 60% OFF )</h6>
                                                <a href="#" class="btn btn-dark btn-lg mb-2 mt-3" > View </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="backside" style={{width:'92%'}}>
                                        <div class="card">
                                            <div class="card-body text-center mt-4">
                                                <h4 class="card-title">Classy Black - Metal NFC  </h4>

                                                <h4 class="card-title">  Business Visiting Card</h4>
                                                <p class="card-text p-4" style={{ fontSize: '17px' }}>This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.</p>
                                                

                                                    <div className='social_media pt-2'>
                                                        <a href='https://www.facebook.com/customcliq' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <FacebookIcon style={{ fontSize: 40 }} /></a>
                                                        <a href='https://www.instagram.com/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <InstagramIcon style={{ fontSize: '40', marginLeft: '1rem' }} /> </a>
                                                        <a href='https://wa.me/919054051551' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <WhatsAppIcon style={{ fontSize: '40', marginLeft: '1rem' }} /> </a>
                                                        <a href='https://www.linkedin.com/company/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <LinkedInIcon style={{ fontSize: '40', marginLeft: '1rem' }} />    </a>
                                                   

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>





                        {/* second row */}

                        <div class="col-lg-4 col-sm-12 col-md-6" style={{marginTop:'2rem'}}>
                            <div class="image-flip" >
                                <div class="mainflip flip-0">
                                    <div class="frontside">
                                        <div class="card" style={{width:'92%'}}>
                                            <div class="card-body text-center">
                                                <p><img class=" img-fluid" src={img4} /></p>
                                                <h6 class="card-title" style={{ fontWeight: 'bold', letterSpacing: '1px',fontSize:'24px' }}>Classy Black - Metal NFC</h6>
                                                <p class="card-text" style={{ fontSize: '19px' }}>Business Visiting Card</p>
                                                <h6 style={{ fontWeight: 'bold', fontSize: '20px' }}>₹ 1999 ( 60% OFF )</h6>
                                                <a href="#" class="btn btn-dark btn-lg mb-2 mt-3" > View </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="backside" style={{width:'92%'}}>
                                        <div class="card">
                                            <div class="card-body text-center mt-4">
                                                <h4 class="card-title">Classy Black - Metal NFC  </h4>

                                                <h4 class="card-title">  Business Visiting Card</h4>
                                                <p class="card-text p-4" style={{ fontSize: '17px' }}>This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.</p>
                                                

                                                    <div className='social_media pt-2'>
                                                        <a href='https://www.facebook.com/customcliq' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <FacebookIcon style={{ fontSize: 40 }} /></a>
                                                        <a href='https://www.instagram.com/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <InstagramIcon style={{ fontSize: '40', marginLeft: '1rem' }} /> </a>
                                                        <a href='https://wa.me/919054051551' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <WhatsAppIcon style={{ fontSize: '40', marginLeft: '1rem' }} /> </a>
                                                        <a href='https://www.linkedin.com/company/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <LinkedInIcon style={{ fontSize: '40', marginLeft: '1rem' }} />    </a>
                                                   

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="col-lg-4 col-sm-12 col-md-6" style={{marginTop:'2rem'}}>
                            <div class="image-flip" >
                                <div class="mainflip flip-0">
                                    <div class="frontside">
                                        <div class="card" style={{width:'92%'}}>
                                            <div class="card-body text-center">
                                                <p><img class=" img-fluid" src={img5} /></p>
                                                <h6 class="card-title" style={{ fontWeight: 'bold', letterSpacing: '1px',fontSize:'24px' }}>Classy Black - Metal NFC</h6>
                                                <p class="card-text" style={{ fontSize: '19px' }}>Business Visiting Card</p>
                                                <h6 style={{ fontWeight: 'bold', fontSize: '20px' }}>₹ 1999 ( 60% OFF )</h6>
                                                <a href="#" class="btn btn-dark btn-lg mb-2 mt-3" > View </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="backside" style={{width:'92%'}}>
                                        <div class="card">
                                            <div class="card-body text-center mt-4">
                                                <h4 class="card-title">Classy Black - Metal NFC  </h4>

                                                <h4 class="card-title">  Business Visiting Card</h4>
                                                <p class="card-text p-4" style={{ fontSize: '17px' }}>This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.</p>
                                                

                                                    <div className='social_media pt-2'>
                                                        <a href='https://www.facebook.com/customcliq' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <FacebookIcon style={{ fontSize: 40 }} /></a>
                                                        <a href='https://www.instagram.com/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <InstagramIcon style={{ fontSize: '40', marginLeft: '1rem' }} /> </a>
                                                        <a href='https://wa.me/919054051551' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <WhatsAppIcon style={{ fontSize: '40', marginLeft: '1rem' }} /> </a>
                                                        <a href='https://www.linkedin.com/company/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <LinkedInIcon style={{ fontSize: '40', marginLeft: '1rem' }} />    </a>
                                                   

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div class="col-lg-4 col-sm-12 col-md-6" style={{marginTop:'2rem'}}>
                            <div class="image-flip" >
                                <div class="mainflip flip-0">
                                    <div class="frontside">
                                        <div class="card" style={{width:'92%'}}>
                                            <div class="card-body text-center">
                                                <p><img class=" img-fluid" src={img6} /></p>
                                                <h6 class="card-title" style={{ fontWeight: 'bold', letterSpacing: '1px',fontSize:'24px' }}>Classy Black - Metal NFC</h6>
                                                <p class="card-text" style={{ fontSize: '19px' }}>Business Visiting Card</p>
                                                <h6 style={{ fontWeight: 'bold', fontSize: '20px' }}>₹ 1999 ( 60% OFF )</h6>
                                                <a href="#" class="btn btn-dark btn-lg mb-2 mt-3" > View </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="backside" style={{width:'92%'}}>
                                        <div class="card">
                                            <div class="card-body text-center mt-4">
                                                <h4 class="card-title">Classy Black - Metal NFC  </h4>

                                                <h4 class="card-title">  Business Visiting Card</h4>
                                                <p class="card-text p-4" style={{ fontSize: '17px' }}>This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.</p>
                                                

                                                    <div className='social_media pt-2'>
                                                        <a href='https://www.facebook.com/customcliq' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <FacebookIcon style={{ fontSize: 40 }} /></a>
                                                        <a href='https://www.instagram.com/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <InstagramIcon style={{ fontSize: '40', marginLeft: '1rem' }} /> </a>
                                                        <a href='https://wa.me/919054051551' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <WhatsAppIcon style={{ fontSize: '40', marginLeft: '1rem' }} /> </a>
                                                        <a href='https://www.linkedin.com/company/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <LinkedInIcon style={{ fontSize: '40', marginLeft: '1rem' }} />    </a>
                                                   

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        {/* third row */}
                        <div class="col-lg-4 col-sm-12 col-md-6" style={{marginTop:'2rem'}}>
                            <div class="image-flip" >
                                <div class="mainflip flip-0">
                                    <div class="frontside">
                                        <div class="card" style={{width:'92%'}}>
                                            <div class="card-body text-center">
                                                <p><img class=" img-fluid" src={img7} /></p>
                                                <h6 class="card-title" style={{ fontWeight: 'bold', letterSpacing: '1px',fontSize:'24px' }}>Classy Black - Metal NFC</h6>
                                                <p class="card-text" style={{ fontSize: '19px' }}>Business Visiting Card</p>
                                                <h6 style={{ fontWeight: 'bold', fontSize: '20px' }}>₹ 1999 ( 60% OFF )</h6>
                                                <a href="#" class="btn btn-dark btn-lg mb-2 mt-3" > View </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="backside" style={{width:'92%'}}>
                                        <div class="card">
                                            <div class="card-body text-center mt-4">
                                                <h4 class="card-title">Classy Black - Metal NFC  </h4>

                                                <h4 class="card-title">  Business Visiting Card</h4>
                                                <p class="card-text p-4" style={{ fontSize: '17px' }}>This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.</p>
                                                

                                                    <div className='social_media pt-2'>
                                                        <a href='https://www.facebook.com/customcliq' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <FacebookIcon style={{ fontSize: 40 }} /></a>
                                                        <a href='https://www.instagram.com/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <InstagramIcon style={{ fontSize: '40', marginLeft: '1rem' }} /> </a>
                                                        <a href='https://wa.me/919054051551' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <WhatsAppIcon style={{ fontSize: '40', marginLeft: '1rem' }} /> </a>
                                                        <a href='https://www.linkedin.com/company/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <LinkedInIcon style={{ fontSize: '40', marginLeft: '1rem' }} />    </a>
                                                   

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-4 col-sm-12 col-md-6" style={{marginTop:'2rem'}}>
                            <div class="image-flip" >
                                <div class="mainflip flip-0">
                                    <div class="frontside">
                                        <div class="card" style={{width:'92%'}}>
                                            <div class="card-body text-center">
                                                <p><img class=" img-fluid" src={img8} /></p>
                                                <h6 class="card-title" style={{ fontWeight: 'bold', letterSpacing: '1px',fontSize:'24px' }}>Classy Black - Metal NFC</h6>
                                                <p class="card-text" style={{ fontSize: '19px' }}>Business Visiting Card</p>
                                                <h6 style={{ fontWeight: 'bold', fontSize: '20px' }}>₹ 1999 ( 60% OFF )</h6>
                                                <a href="#" class="btn btn-dark btn-lg mb-2 mt-3" > View </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="backside" style={{width:'92%'}}>
                                        <div class="card">
                                            <div class="card-body text-center mt-4">
                                                <h4 class="card-title">Classy Black - Metal NFC  </h4>

                                                <h4 class="card-title">  Business Visiting Card</h4>
                                                <p class="card-text p-4" style={{ fontSize: '17px' }}>This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.</p>
                                                

                                                    <div className='social_media pt-2'>
                                                        <a href='https://www.facebook.com/customcliq' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <FacebookIcon style={{ fontSize: 40 }} /></a>
                                                        <a href='https://www.instagram.com/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <InstagramIcon style={{ fontSize: '40', marginLeft: '1rem' }} /> </a>
                                                        <a href='https://wa.me/919054051551' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <WhatsAppIcon style={{ fontSize: '40', marginLeft: '1rem' }} /> </a>
                                                        <a href='https://www.linkedin.com/company/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <LinkedInIcon style={{ fontSize: '40', marginLeft: '1rem' }} />    </a>
                                                   

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="col-lg-4 col-sm-12 col-md-6" style={{marginTop:'2rem'}}>
                            <div class="image-flip" >
                                <div class="mainflip flip-0">
                                    <div class="frontside">
                                        <div class="card" style={{width:'92%'}}>
                                            <div class="card-body text-center">
                                                <p><img class=" img-fluid" src={img9} /></p>
                                                <h6 class="card-title" style={{ fontWeight: 'bold', letterSpacing: '1px',fontSize:'24px' }}>Classy Black - Metal NFC</h6>
                                                <p class="card-text" style={{ fontSize: '19px' }}>Business Visiting Card</p>
                                                <h6 style={{ fontWeight: 'bold', fontSize: '20px' }}>₹ 1999 ( 60% OFF )</h6>
                                                <a href="#" class="btn btn-dark btn-lg mb-2 mt-3" > View </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="backside" style={{width:'92%'}}>
                                        <div class="card">
                                            <div class="card-body text-center mt-4">
                                                <h4 class="card-title">Classy Black - Metal NFC  </h4>

                                                <h4 class="card-title">  Business Visiting Card</h4>
                                                <p class="card-text p-4" style={{ fontSize: '17px' }}>This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.</p>
                                                

                                                    <div className='social_media pt-2'>
                                                        <a href='https://www.facebook.com/customcliq' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <FacebookIcon style={{ fontSize: 40 }} /></a>
                                                        <a href='https://www.instagram.com/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <InstagramIcon style={{ fontSize: '40', marginLeft: '1rem' }} /> </a>
                                                        <a href='https://wa.me/919054051551' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <WhatsAppIcon style={{ fontSize: '40', marginLeft: '1rem' }} /> </a>
                                                        <a href='https://www.linkedin.com/company/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <LinkedInIcon style={{ fontSize: '40', marginLeft: '1rem' }} />    </a>
                                                   

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        {/* four row */}

                        <div class="col-lg-4 col-sm-12 col-md-6" style={{marginTop:'2rem'}}>
                            <div class="image-flip" >
                                <div class="mainflip flip-0">
                                    <div class="frontside">
                                        <div class="card" style={{width:'92%'}}>
                                            <div class="card-body text-center">
                                                <p><img class=" img-fluid" src={img13} /></p>
                                                <h6 class="card-title" style={{ fontWeight: 'bold', letterSpacing: '1px',fontSize:'24px' }}>Classy Black - Metal NFC</h6>
                                                <p class="card-text" style={{ fontSize: '19px' }}>Business Visiting Card</p>
                                                <h6 style={{ fontWeight: 'bold', fontSize: '20px' }}>₹ 1999 ( 60% OFF )</h6>
                                                <a href="#" class="btn btn-dark btn-lg mb-2 mt-3" > View </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="backside" style={{width:'92%'}}>
                                        <div class="card">
                                            <div class="card-body text-center mt-4">
                                                <h4 class="card-title">Classy Black - Metal NFC  </h4>

                                                <h4 class="card-title">  Business Visiting Card</h4>
                                                <p class="card-text p-4" style={{ fontSize: '17px' }}>This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.</p>
                                                

                                                    <div className='social_media pt-2'>
                                                        <a href='https://www.facebook.com/customcliq' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <FacebookIcon style={{ fontSize: 40 }} /></a>
                                                        <a href='https://www.instagram.com/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <InstagramIcon style={{ fontSize: '40', marginLeft: '1rem' }} /> </a>
                                                        <a href='https://wa.me/919054051551' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <WhatsAppIcon style={{ fontSize: '40', marginLeft: '1rem' }} /> </a>
                                                        <a href='https://www.linkedin.com/company/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <LinkedInIcon style={{ fontSize: '40', marginLeft: '1rem' }} />    </a>
                                                   

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="col-lg-4 col-sm-12 col-md-6" style={{marginTop:'2rem'}}>
                            <div class="image-flip" >
                                <div class="mainflip flip-0">
                                    <div class="frontside">
                                        <div class="card" style={{width:'92%'}}>
                                            <div class="card-body text-center">
                                                <p><img class=" img-fluid" src={img14} /></p>
                                                <h6 class="card-title" style={{ fontWeight: 'bold', letterSpacing: '1px',fontSize:'24px' }}>Classy Black - Metal NFC</h6>
                                                <p class="card-text" style={{ fontSize: '19px' }}>Business Visiting Card</p>
                                                <h6 style={{ fontWeight: 'bold', fontSize: '20px' }}>₹ 1999 ( 60% OFF )</h6>
                                                <a href="#" class="btn btn-dark btn-lg mb-2 mt-3" > View </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="backside" style={{width:'92%'}}>
                                        <div class="card">
                                            <div class="card-body text-center mt-4">
                                                <h4 class="card-title">Classy Black - Metal NFC  </h4>

                                                <h4 class="card-title">  Business Visiting Card</h4>
                                                <p class="card-text p-4" style={{ fontSize: '17px' }}>This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.</p>
                                                

                                                    <div className='social_media pt-2'>
                                                        <a href='https://www.facebook.com/customcliq' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <FacebookIcon style={{ fontSize: 40 }} /></a>
                                                        <a href='https://www.instagram.com/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <InstagramIcon style={{ fontSize: '40', marginLeft: '1rem' }} /> </a>
                                                        <a href='https://wa.me/919054051551' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <WhatsAppIcon style={{ fontSize: '40', marginLeft: '1rem' }} /> </a>
                                                        <a href='https://www.linkedin.com/company/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <LinkedInIcon style={{ fontSize: '40', marginLeft: '1rem' }} />    </a>
                                                   

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="col-lg-4 col-sm-12 col-md-6" style={{marginTop:'2rem'}}>
                            <div class="image-flip" >
                                <div class="mainflip flip-0">
                                    <div class="frontside">
                                        <div class="card" style={{width:'92%'}}>
                                            <div class="card-body text-center">
                                                <p><img class=" img-fluid" src={img15} /></p>
                                                <h6 class="card-title" style={{ fontWeight: 'bold', letterSpacing: '1px',fontSize:'24px' }}>Classy Black - Metal NFC</h6>
                                                <p class="card-text" style={{ fontSize: '19px' }}>Business Visiting Card</p>
                                                <h6 style={{ fontWeight: 'bold', fontSize: '20px' }}>₹ 1999 ( 60% OFF )</h6>
                                                <a href="#" class="btn btn-dark btn-lg mb-2 mt-3" > View </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="backside" style={{width:'92%'}}>
                                        <div class="card">
                                            <div class="card-body text-center mt-4">
                                                <h4 class="card-title">Classy Black - Metal NFC  </h4>

                                                <h4 class="card-title">  Business Visiting Card</h4>
                                                <p class="card-text p-4" style={{ fontSize: '17px' }}>This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.</p>
                                                

                                                    <div className='social_media pt-2'>
                                                        <a href='https://www.facebook.com/customcliq' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <FacebookIcon style={{ fontSize: 40 }} /></a>
                                                        <a href='https://www.instagram.com/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <InstagramIcon style={{ fontSize: '40', marginLeft: '1rem' }} /> </a>
                                                        <a href='https://wa.me/919054051551' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <WhatsAppIcon style={{ fontSize: '40', marginLeft: '1rem' }} /> </a>
                                                        <a href='https://www.linkedin.com/company/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <LinkedInIcon style={{ fontSize: '40', marginLeft: '1rem' }} />    </a>
                                                   

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                        {/* five row */}

                        <div class="col-lg-4 col-sm-12 col-md-6" style={{marginTop:'2rem'}}>
                            <div class="image-flip" >
                                <div class="mainflip flip-0">
                                    <div class="frontside">
                                        <div class="card" style={{width:'92%'}}>
                                            <div class="card-body text-center">
                                                <p><img class=" img-fluid" src={img10} /></p>
                                                <h6 class="card-title" style={{ fontWeight: 'bold', letterSpacing: '1px',fontSize:'24px' }}>Classy Black - Metal NFC</h6>
                                                <p class="card-text" style={{ fontSize: '19px' }}>Business Visiting Card</p>
                                                <h6 style={{ fontWeight: 'bold', fontSize: '20px' }}>₹ 1999 ( 60% OFF )</h6>
                                                <a href="#" class="btn btn-dark btn-lg mb-2 mt-3" > View </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="backside" style={{width:'92%'}}>
                                        <div class="card">
                                            <div class="card-body text-center mt-4">
                                                <h4 class="card-title">Classy Black - Metal NFC  </h4>

                                                <h4 class="card-title">  Business Visiting Card</h4>
                                                <p class="card-text p-4" style={{ fontSize: '17px' }}>This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.</p>
                                                

                                                    <div className='social_media pt-2'>
                                                        <a href='https://www.facebook.com/customcliq' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <FacebookIcon style={{ fontSize: 40 }} /></a>
                                                        <a href='https://www.instagram.com/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <InstagramIcon style={{ fontSize: '40', marginLeft: '1rem' }} /> </a>
                                                        <a href='https://wa.me/919054051551' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <WhatsAppIcon style={{ fontSize: '40', marginLeft: '1rem' }} /> </a>
                                                        <a href='https://www.linkedin.com/company/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <LinkedInIcon style={{ fontSize: '40', marginLeft: '1rem' }} />    </a>
                                                   

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div class="col-lg-4 col-sm-12 col-md-6" style={{marginTop:'2rem'}}>
                            <div class="image-flip" >
                                <div class="mainflip flip-0">
                                    <div class="frontside">
                                        <div class="card" style={{width:'92%'}}>
                                            <div class="card-body text-center">
                                                <p><img class=" img-fluid" src={img11} /></p>
                                                <h6 class="card-title" style={{ fontWeight: 'bold', letterSpacing: '1px',fontSize:'24px' }}>Classy Black - Metal NFC</h6>
                                                <p class="card-text" style={{ fontSize: '19px' }}>Business Visiting Card</p>
                                                <h6 style={{ fontWeight: 'bold', fontSize: '20px' }}>₹ 1999 ( 60% OFF )</h6>
                                                <a href="#" class="btn btn-dark btn-lg mb-2 mt-3" > View </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="backside" style={{width:'92%'}}>
                                        <div class="card">
                                            <div class="card-body text-center mt-4">
                                                <h4 class="card-title">Classy Black - Metal NFC  </h4>

                                                <h4 class="card-title">  Business Visiting Card</h4>
                                                <p class="card-text p-4" style={{ fontSize: '17px' }}>This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.</p>
                                                

                                                    <div className='social_media pt-2'>
                                                        <a href='https://www.facebook.com/customcliq' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <FacebookIcon style={{ fontSize: 40 }} /></a>
                                                        <a href='https://www.instagram.com/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <InstagramIcon style={{ fontSize: '40', marginLeft: '1rem' }} /> </a>
                                                        <a href='https://wa.me/919054051551' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <WhatsAppIcon style={{ fontSize: '40', marginLeft: '1rem' }} /> </a>
                                                        <a href='https://www.linkedin.com/company/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <LinkedInIcon style={{ fontSize: '40', marginLeft: '1rem' }} />    </a>
                                                   

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div class="col-lg-4 col-sm-12 col-md-6" style={{marginTop:'2rem'}}>
                            <div class="image-flip" >
                                <div class="mainflip flip-0">
                                    <div class="frontside">
                                        <div class="card" style={{width:'92%'}}>
                                            <div class="card-body text-center">
                                                <p><img class=" img-fluid" src={img12} /></p>
                                                <h6 class="card-title" style={{ fontWeight: 'bold', letterSpacing: '1px',fontSize:'24px' }}>Classy Black - Metal NFC</h6>
                                                <p class="card-text" style={{ fontSize: '19px' }}>Business Visiting Card</p>
                                                <h6 style={{ fontWeight: 'bold', fontSize: '20px' }}>₹ 1999 ( 60% OFF )</h6>
                                                <a href="#" class="btn btn-dark btn-lg mb-2 mt-3" > View </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="backside" style={{width:'92%'}}>
                                        <div class="card">
                                            <div class="card-body text-center mt-4">
                                                <h4 class="card-title">Classy Black - Metal NFC  </h4>

                                                <h4 class="card-title">  Business Visiting Card</h4>
                                                <p class="card-text p-4" style={{ fontSize: '17px' }}>This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.This is basic card with image on top, title, description and button.</p>
                                                

                                                    <div className='social_media pt-2'>
                                                        <a href='https://www.facebook.com/customcliq' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <FacebookIcon style={{ fontSize: 40 }} /></a>
                                                        <a href='https://www.instagram.com/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <InstagramIcon style={{ fontSize: '40', marginLeft: '1rem' }} /> </a>
                                                        <a href='https://wa.me/919054051551' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <WhatsAppIcon style={{ fontSize: '40', marginLeft: '1rem' }} /> </a>
                                                        <a href='https://www.linkedin.com/company/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <LinkedInIcon style={{ fontSize: '40', marginLeft: '1rem' }} />    </a>
                                                   

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>







            {/* footer part*/}
            <Footer />

        </>
    )
}

export default Stand_Card