import React, { useEffect } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

import AddIcCallSharpIcon from '@mui/icons-material/AddIcCallSharp';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import EmailIcon from '@mui/icons-material/Email';

import FmdGoodIcon from '@mui/icons-material/FmdGood';

import AOS from 'aos';
import 'aos/dist/aos.css';



const Contact = () => {


    useEffect(() => {
        AOS.init({
            duration: 1200,  // Animation duration
            once: true,  // Whether animation should happen only once
        });
    }, []);
    return (

        <>

            {/* navbar  */}

            <Navbar />



            {/* contact page----------------------- */}

            <div className='container-fluid main_contact'>
                <div className='container' style={{ paddingBottom: '8rem' }} >
                    <h4 >Contact us</h4>
                    <h6 >Tell us more and we'll find the best solution for you</h6>

                    <div className='row mt-5'>

                        <div className='col-lg-3 col-md-6 col-sm-12 pt-4 ' >
                            <div className="card shadow-lg text-center about_contact1 "  >
                                <div className="card-body text-white">
                                    <AddIcCallSharpIcon className='logo_contact' />

                                    <h6 style={{ fontSize: "21px" }} >Call Us</h6>

                                    <p style={{ fontSize: '19px', paddingBottom: '18px' }} >+91 9054051551</p>

                                </div>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-6 col-sm-12  pt-4  ' >
                            <div className="card shadow-lg text-center about_contact1" style={{ borderRadius: '18px' }}>
                                <div className="card-body text-white">

                                    <EmailIcon className='logo_contact' />

                                    <h6 style={{ fontSize: "21px" }} >Email Us</h6>

                                    <p style={{ fontSize: '19px', paddingBottom: '18px' }} >customcliq@gmail.com</p>

                                </div>
                            </div>
                        </div>

                        <div className='col-lg-3 col-md-6 col-sm-12  pt-4  ' >
                            <div className="card shadow-lg text-center about_contact1" style={{ borderRadius: '18px' }}>
                                <div className="card-body text-white">

                                    <WhatsAppIcon className='logo_contact' />

                                    <h6 style={{ fontSize: "21px" }} >WhatsApp</h6>

                                    <p style={{ fontSize: '19px', paddingBottom: '18px' }} >+91 9054051551</p>

                                </div>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-6 col-sm-12  pt-4 '>
                            <div className="card shadow-lg text-center about_contact1" style={{ borderRadius: '18px' }}>
                                <div className="card-body text-white">

                                    <FmdGoodIcon className='logo_contact' />

                                    <h6 style={{ fontSize: "21px" }} >Address</h6>

                                    <p style={{ fontSize: '17px' }} >122, Times shoppers <br />Sarthana Jakatnaka, Surat.</p>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* map and form */}
                    <div className='main_form' style={{ marginTop: '4rem' }}>

                        <div className="card shadow-lg map_info text-center pt-3 ">

                            <form className='p-4 mt-3' style={{ width: '80%', margin: 'auto' }}>

                                <div className='d-flex two_field'>

                                    <input type='text' name='fname' placeholder='Your Name' />

                                    <input type='email' name='email' placeholder='Your Email' style={{ marginLeft: '20px' }} />

                                </div>

                                <input type='number' name='phone_num' placeholder='Contact Number' style={{ marginTop: '2rem' }} />

                                <input type='text' name='subject' placeholder='Subject' style={{ marginTop: '2rem' }} />

                                <textarea style={{ marginTop: '2rem', color: 'grey', height: '100px' }} placeholder='Message' />

                                <button type="button" class="btn btn-lg  mt-3 mb-3" style={{ background: '#132b43', color: 'white', borderRadius: '15px' }}> Send Message </button>
                            </form>

                        </div>
                    </div>



                    {/* map information*/}

                    <div className="map_info " style={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', marginTop: '5rem' }}  >

                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.0613195300625!2d72.89705647508659!3d21.22941688047066!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be0459e5a292ebb%3A0x67b67b7f5796cd51!2sCustomCliQ%20%7C%20Smart%20NFC%20Card%20%26%20QR%20Technology!5e0!3m2!1sen!2sin!4v1726644536556!5m2!1sen!2sin" width="100%"
                            height="550"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"  ></iframe>

                    </div>

                </div>
            </div>





            {/* footer */}

            <Footer />


        </>
    )
}

export default Contact