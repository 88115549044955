import React, { useContext } from 'react';

import './Cart.css';


import img1 from '../img/cards/c1.png';

import AddIcon from '@mui/icons-material/Add';

import RemoveIcon from '@mui/icons-material/Remove';

import DeleteIcon from '@mui/icons-material/Delete';

import { products } from './Products';

import { CartContext } from './Cart';

import { cardData }  from '../All_Type_Card'



const Items = ({id, description, title, image, price, quantity}) => {

  const { removeItem, increment, decrement } = useContext(CartContext);


  return (
    <>
        <div className='items-info'>
              <div className='product-img '>

                <img src={image} alt='img' />

              </div>

              <div className='title '>
                <h4>{title} </h4>
                <p>{description}</p>
              </div>

              <div className='add-minus-quantity'>
              <RemoveIcon className='minus icon'   onClick={() => decrement(id)}/>

              <input type='text' placeholder={quantity} />

              <AddIcon className='plus icon' onClick={() => increment(id)}/> 
              </div>


              <div className='price  '>
                <h3>{price}</h3>
              </div>

              <div className='remove-item'>
                <DeleteIcon className='remove icon' onClick={() => removeItem(id)} />
              </div>
            </div>
            
            <hr />
    </>
  )
}

export default Items