export const reducer = (state, action) => {


  


  //clear cart
  if (action.type === "CLEAR_CART") {
    return { ...state, item: [] };
  }

  //remove item
  if(action.type === "REMOVE_ITEM") {
    return {
      ...state,
      item: state.item.filter((CurElm) => {
        return CurElm.id !== action.payload;
      })
    }
  }

  // increment
  if(action.type === "INCREMENT"){
    let updatedCart = state.item.map((curElem) => {
      if(curElem.id === action.payload){
        return {...curElem, quantity: curElem.quantity + 1}
      }
      return curElem;
    })

    return {...state, item: updatedCart}
  }

  // decrement
  if (action.type === "DECREMENT") {
    const updatedCart = state.item
      .map((curElem) => {
        if (curElem.id === action.payload) {
          return { ...curElem, quantity: curElem.quantity - 1 };
        }
        return curElem;
      })
      .filter((curElem) => curElem.quantity !== 0);
    return { ...state, item: updatedCart };
  }

  // total
  if (action.type === "GET_TOTAL") {
    let { totalItem, totalAmount } = state.item.reduce(
      (accum, curVal) => {
        let { price, quantity } = curVal;
  
        // Extract only the numeric price part before any discount or text
        let numericPrice = parseFloat(price.split(' ')[1].replace(/[^\d.-]/g, ''));
  
        let updatedTotalAmount = numericPrice * quantity;
        accum.totalAmount += updatedTotalAmount;
  
        accum.totalItem += quantity;
        return accum;
      },
      {
        totalItem: 0,
        totalAmount: 0,
      }
    );
    return { ...state, totalItem, totalAmount };
  }
  
  

  return state;
}
