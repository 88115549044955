import React from 'react'
import img1 from './img/form2.png'
import Navbar from './Navbar';
import Footer from './Footer'

import { NavLink } from 'react-router-dom';

const Corporate_Form = () => {
  return (
 <>



    {/* // this is navbar---------------------------------// */}

    
    <div className='navbar'>  <Navbar /> </div>





    <div className='main_corporate_form' style={{ paddingTop: '6rem', clear: 'both',paddingBottom:'5rem' }}>
      <h4>CustomCliQ for Corporates</h4>
      <h6>Let's get connected.</h6>

      <div className='btn' style={{ border: 'none', display: 'flex', justifyContent: 'center', marginTop: '1rem', marginBottom: '1rem' }}>
          <button type="submit" style={{ padding: '10px 28px' }} className="btn btn-lg btn-dark ">   <NavLink to="/Bulk_Form" style={{ textDecoration: 'none', color: 'white' }}>Bulk Form</NavLink></button>
        </div>

      <div className='card mt-5 '>
        <div className="card_img pt-4" style={{ margin: 'auto' }}>
          <img src={img1} className="card-img-top img-fluid img-thumbnail" alt="Image here" style={{ width: '100%', height: '450px', border: '0' }} />

        </div>



        {/* from */}

        <form className='from_card pt-2'>

          <h6 style={{ fontSize: '30px' }}>Get in touch with CustomCliQ</h6>

          <p style={{ textAlign: 'center', fontSize: '18px', paddingTop: '12px' }}>We're delighted about your interest in Scube Cards for your business. <br />Please provide us with information about your business, and we'll be in touch soon.</p>

          <p style={{ textAlign: 'center', fontSize: '18px', paddingTop: '8px', fontWeight: 'bold' }}>Tell us about your business</p>


          <div class="mb-3">
            <input type="text" className="form-control " id="fname" placeholder='First Name*' />
          </div>

          <div class="mb-3">
            <input type="text" className="form-control " id="lname" placeholder='Last Name*' />
          </div>

          <div class="mb-3">
            <input type="email" className="form-control  " id="exampleInputEmail1" placeholder='Email ID*' />
          </div>

          <div class="mb-3">
            <input type="text" className="form-control " id="designation" placeholder='Designation*' />
          </div>

          <div class="mb-3">
            <input type="text" className="form-control " id="company" placeholder='Company Name*' />
          </div>

          <div class="mb-3">
            <input type="text" className="form-control " id="industry" placeholder='Industry*' />
          </div>

          <div class="mb-3">
            <input type="number" className="form-control " id="employee" placeholder='Number of Employees*' />
          </div>

          <select class="form-select  form-select-sm" >
            <option value="1" selected>India</option>
            <option value="2">America</option>
            <option value="3">Australia</option>
            <option value="3">Pakistan</option>
          </select>

          <label for='msg' style={{ display: 'flex', justifyContent: 'center' }}>How should we get in touch?</label>
          <select class="form-select mb-3 form-select-sm" >
            <option value="1" selected>Phone call</option>
            <option value="2">Whatsapp Message</option>
            <option value="3">Email</option>

          </select>

          <div class="mb-3">
            <input type="number" className="form-control " id="number" placeholder='Phone Number*' />
          </div>

          <div class="mb-3">
            <input type="date" className="form-control " id="date" placeholder='Date*' />
          </div>

          <label for='msg' style={{ display: 'flex', justifyContent: 'center' }}>How did you hear about scube smart business card?</label>
          <select class="form-select mb-3 form-select-sm" >
            <option value="1" selected>Facebook</option>
            <option value="2">Instagram</option>
            <option value="3">Youtube</option>
            <option value="4">Google</option>
            <option value="5">Referral</option>
            <option value="6">Others</option>
          </select>

          <div className='textarea pt-2' style={{ display: 'flex', justifyContent: 'center', width: '80%', margin:'auto' }}>
            <textarea id="text" name="text" rows="6" cols="100"  placeholder='Additional notes/ description' style={{height:'100px'}}>
            </textarea>
          </div>


          <p className=' pt-4' style={{ display: 'flex', justifyContent: 'flex-start', fontSize: '17px', paddingLeft: '7rem' }}>By sharing your contact details, you can be assured that Scube will utilise them responsibly and securely.</p>


          <div className='btn' style={{ display: 'flex', justifyContent: 'center',marginTop:'2rem',marginBottom:'3rem' }}>
            <button type="submit" style={{ padding: '10px 28px', fontSize: '20px' }} className="btn btn-lg btn-dark ">Submit</button>
          </div>
        </form>
      </div>
    </div>



    {/* footer page----------------- */}
    <div className='footer'>
      <Footer />

    </div>


    </>
  )
}

export default Corporate_Form