import React from 'react';
import { NavLink } from 'react-router-dom';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import PersonIcon from '@mui/icons-material/Person';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';

import logo from '../src/img/flogo.png';

const Navbar = () => {
  return (

    <div className="container-fluid  navbar_main mb-5">
      <nav class="navbar navbar-expand-lg" style={{ background: '#f3f4f6' }}>
        <div class="container">
          <div className='navbar-brand ms-4'>
            <img src={logo} alt="Logo"
              className="img-fluid"
              style={{ width: '70px', height: '60px' }} />
          </div>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse " id="navbarSupportedContent">
            <ul class="navbar-nav mb-2 mb-lg-0 ms-auto">
              <li class="nav-item active">
                <NavLink className="nav_link ps-2 pe-2" aria-current="page" to="/" >Home</NavLink>
              </li>

              <li class="nav-item  ms-4">
                <NavLink className="nav_link ps-2 pe-2" to="/works">About Us</NavLink>
              </li>

              {/* <li class="nav-item  ms-4">
                <NavLink className="nav_link ps-2 pe-2" aria-disabled="true" to='/card'>Product</NavLink>
              </li> */}

              <li class="nav-item ms-4 dropdown">
                <NavLink to="#" className="nav_link ps-2 pe-2" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Product <span class="caret"></span></NavLink>
                <ul class="dropdown-menu">

                  <NavLink className="nav_link ps-2 pe-2" aria-disabled="true" to='/card'>Business Card</NavLink>

                  <NavLink className="nav_link ps-2 pe-2" aria-disabled="true" to='/Stand_Card'>Stand Card</NavLink>

                  {/* <li role="separator" class="divider"></li> */}
                </ul>
              </li>

              <li class="nav-item  ms-4">
                <NavLink className="nav_link ps-2 pe-2" aria-disabled="true" to='/Contact'>Contact Us</NavLink>
              </li>




              {/* <li class="nav-item  ms-4">
                <NavLink className="nav_link ps-2 pe-2" aria-disabled="true" to='/affliate'>Affliate</NavLink>
              </li> */}


              {/* <li class="nav-item  ms-4">
                <NavLink className="nav_link ps-2 pe-2" aria-disabled="true" to='/Bulk_Form'>Form</NavLink>
              </li> */}



            </ul>

            <form className="d-flex btn_links" style={{ marginLeft: '10rem' }} role="search">
              <button className="btn btn-outline-dark" type="submit" >  <SavedSearchIcon /> </button>
              <button className="btn btn-outline-dark ms-3" type="submit">  <PersonIcon /> </button>
              <NavLink className="nav_link ps-2 pe-2" to='/cart'> <button className="btn btn-outline-dark ms-3" type="submit">  <ShoppingCartIcon /> </button></NavLink>

            </form>

          </div>
        </div>
      </nav>
    </div>
  )
}

export default Navbar