import React, { useContext } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import Items from './Items.js';
import arrow from '../img/cards/arrow.png';
import cart from '../img/cards/cart.png';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { CartContext } from './Cart.js';

const ContextCart = () => {
  const { item, totalAmount, totalItem, clearCart } = useContext(CartContext);

  const adminPhoneNumber = '919904464465'; // Admin's WhatsApp number in international format

  // Function to generate message and redirect to WhatsApp
  
  const handleWhatsAppClick = () => {
    let message = `Hello, I would like to inquire about the following items:\n\n`;
  
    // Table header
    message += `Item          | Description          | Price          | Quantity\n`;
    message += `--------------------------------------------\n`;
  
    // Loop over items and format them like rows in a table
    item.forEach((currItem, index) => {
      const itemTitle = currItem.title.padEnd(12);  // Adjust padding based on item title length
      const itemDescription = currItem.description.padEnd(18); // Adjust padding for description
      const itemPrice = `₹${currItem.price}`.padEnd(7); // Price column
      const itemQuantity = `${currItem.quantity}`.padEnd(5); // Quantity column
  
      message += `${itemTitle} | ${itemDescription} | ${itemPrice} | ${itemQuantity}\n`;
    });
  
    message += `--------------------------------------------\n`;
    message += `Total Items: ${totalItem}\nTotal Amount: ₹${totalAmount}\n\n`;
  
    // WhatsApp API URL with pre-filled message
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${adminPhoneNumber}&text=${encodeURIComponent(message)}`;
  
    // Redirect to WhatsApp chat
    window.open(whatsappUrl, '_blank');
  };
  

  return (
    <>
      <header className='pt-5'>
        <div className='continue-shopping'>
          <img src={arrow} alt="img" className='arrow-icon' />
          <h3> continue shopping</h3>
        </div>

        <div className='cart-icon'>
          <img src={cart} alt='img' />
          <p>{totalItem}</p>
        </div>
      </header>

      <section className='main-cart-section'>
        <h1 className='text-center'>shopping cart</h1>
        <p className='text-center total-items'>you have <span className='total-items-count'> {totalItem} </span> item in shopping cart</p>

        <div className='cart-items'>
          <div className='cart-items-container'>
            <Scrollbars>
              {item.map((CurrItem) => {
                return <Items key={CurrItem.id} {...CurrItem} />;
              })}
            </Scrollbars>
          </div>
        </div>

        <div className='card-total'>
          <h3>Card Total : <span>{totalAmount}₹</span></h3>

          <button onClick={handleWhatsAppClick}>
            <WhatsAppIcon style={{ fontSize: '2rem' }} /> Chat With Us
          </button>

          <button className='clear-cart' onClick={clearCart}>Clear Cart</button>
        </div>
      </section>
    </>
  );
};

export default ContextCart;
