import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import './product.css'

import img1 from '../../src/img/cards/c1.png';
import img2 from '../img/cards/pvc4.png';
import img3 from '../img/cards/ca.png';
import img4 from '../img/cards/P4.png';
import img5 from '../img/cards/i2.png';
import img6 from '../img/cards/r4.png';


const MyCarousel = () => {
    return (
        <>
            <div className='main_carousal'>
        <OwlCarousel
            className="owl-theme"
            loop
            margin={10}
            nav
            items={4}
            autoplay
            smartSpeed={450} 
        >

            <div className="item">
                <img src={img1} alt="Item 1" />
                <h5 className='pt-2'>Metal NFC</h5>
                <h6>Business Visiting Card</h6>
                <p >₹ 1999 <span style={{color:'orange'}}> ( 60% OFF ) </span></p>
            </div>
            <div className="item">
                <img src={img2} alt="Item 2" />
                <h5 className='pt-2'>PVC NFC</h5>
                <h6>Business Visiting Card</h6>
                <p >₹ 799 <span style={{color:'orange'}}> ( 52% OFF ) </span></p>
            </div>
            <div className="item">
                <img src={img3} alt="Item 3" />
                <h5 className='pt-2'>Engraved NFC</h5>
                <h6>Business Visiting Card</h6>
                <p >₹ 2499 <span style={{color:'orange'}}> ( 55% OFF ) </span></p>
            </div>

            <div className="item">
                <img src={img4} alt="Item 3" />
                <h5 className='pt-2'>Architect PVC NFC</h5>
                <h6>Business Visiting Card</h6>
                <p >₹ 899 <span style={{color:'orange'}}> ( 45% OFF ) </span></p>
            </div>

            <div className="item">
                <img src={img5} alt="Item 3" />
                <h5 className='pt-2'>Architect PVC NFC</h5>
                <h6>Business Visiting Card</h6>
                <p >₹ 899 <span style={{color:'orange'}}> ( 45% OFF ) </span></p>
            </div>
             
            <div className="item">
                <img src={img6} alt="Item 3" />
                <h5 className='pt-2'> PVC NFC</h5>
                <h6>Business Visiting Card</h6>
                <p >₹ 499 <span style={{color:'orange'}}> ( 67% OFF ) </span></p>
            </div>
        </OwlCarousel>
        </div>
        </>
    );
};

export default MyCarousel;
