import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Home';
import All_Type_Card from './All_Type_Card';
import Error from './Error';
import How_Works from './How_Works';
import Affliate from './Affliate';
import Bulk_Order_Form from './Bulk_Order_Form';
import Corporate_Form from './Corporate_Form';
import Stand_Card from './Stand_Card';
import Contact from './Contact';
import ScrollToTop from './ScrollToTop';
import Cart from './Component/Cart';
import NFC_Pro from './product/NFC_Pro';
import Sample_Product from './product/Sample_Product';

import CheckoutForm from './Component/ChekoutForm';


const App = () => {
  return (
    <>

 <ScrollToTop />

     <Routes>
      <Route exact path='/' Component={Home} />
      <Route exact path='/card' Component={All_Type_Card} />
      <Route exact path='/works' Component={How_Works} />
      <Route exact path='/affliate' Component={Affliate} />
      <Route exact path='/Bulk_Form' Component={Bulk_Order_Form} />
      <Route exact path='/Corporate_Form' Component={Corporate_Form} />
      <Route exact path='/card/Name' Component={All_Type_Card} />
      <Route exact path='/Stand_Card' Component={Stand_Card} />
      <Route exact path='/Contact' Component={Contact} />
      <Route exact path='/sample' Component={Sample_Product} />

      <Route path="/product/:id" element={<NFC_Pro />} />

      <Route exact path='/Cart' Component={Cart} />

      <Route path='/*' Component={Error} />

      <Route path='/wp' Component={CheckoutForm} />
      
     </Routes>
    </>
  )
}

export default App;