import React, { useState } from 'react';

const CheckoutForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappMessage = `New order from ${name}:\nEmail: ${email}\nMessage: ${message}`;
    const whatsappNumber = 9904464465; // Replace with the admin's WhatsApp number
    const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappMessage)}`;
    
    window.open(whatsappLink, '_blank'); // Opens WhatsApp in a new tab
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Name:
        <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
      </label>
      <label>
        Email:
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
      </label>
      <label>
        Message:
        <textarea value={message} onChange={(e) => setMessage(e.target.value)} required></textarea>
      </label>
      <button type="submit">Send to WhatsApp</button>
    </form>
  );
};

export default CheckoutForm;
