import React from 'react';

// import 'font-awesome/css/font-awesome.min.css';
import 'react-owl-carousel2/lib/styles.css'; // Import owl carousel styles
// import 'react-owl-carousel2/lib/owl.theme.default.css';
import './Customer_review.css'; // Add your custom styles here

import OwlCarousel from 'react-owl-carousel2';

import p1 from '../src/img/client/p1.jpg';
import p2 from '../src/img/client/p2.png';
import p4 from '../src/img/client/p4.png';
import p3 from '../src/img/client/p3.png';

import StarIcon from '@mui/icons-material/Star';
// import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
// import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';


// customer information-----------------//
// const testimonials = [
//     {
//         name: 'John Doe',
//         text: 'This is an amazing product! This is an amazing product! Highly recommend it.This is an amazing product! Highly recommend it.',
//         image: p1,
//     },
//     {
//         name: 'Jane Smith',
//         text: 'I had a fantastic experience with this service.This is an amazing product! Highly recommend it.This is an amazing product! Highly recommend it.',
//         image: p4,
//     },
//     {
//         name: 'Alice Johnson',
//         text: 'Great quality and excellent customer support.This is an amazing product! Highly recommend it.This is an amazing product! Highly recommend it.',
//         image: p3,
//     },

// ];



const options = {
    items: 3,
    loop: true,
    autoplay: true,
    autoplayTimeout: 1000,
    autoplayHoverPause: true,
    dots: true,
    responsive: {
        1000: { items: 3 },
        768: { items: 2 },
        0: { items: 1 },
    },
};

function Customer_review() {
    return (
        <div className="container-fluid bg-black what-say" style={{paddingBottom:'5rem'}}>
            <div className="container">

                <h2 className='text-center' style={{color:'#49a0e3'}}>Our Clients Love Us!</h2>
                <OwlCarousel options={options}>
                    <div className="testimonial">
                        <div className="testimonial-content">
                            <div className="testimonial-icon">
                                <i className="fa fa-quote-left"></i>
                            </div>

                            <div className='img'>
                                <img src={p4} alt='here' style={{ width: '150px', margin: 'auto', borderRadius: '50%' }} className='img-fluid' />
                            </div>
                            <h6>Ayush Chodavadiya</h6>
                            <StarIcon className='client_logo' /> <StarIcon className='client_logo' /><StarIcon className='client_logo' /><StarIcon className='client_logo' /><StarIcon className='client_logo' />
                            <p className="description">
                                It’s stylish, and functional, and has impressed all my clients. I highly recommend it to anyone looking to elevate their digital presence!
                            </p>
                        </div>

                    </div>

                    <div className="testimonial">
                        <div className="testimonial-content">
                            <div className="testimonial-icon">
                                <i className="fa fa-quote-left"></i>
                            </div>
                            <div className='img'>
                                <img src={p1} alt='here' style={{ width: '150px', margin: 'auto', borderRadius: '50%' }} className='img-fluid' />
                            </div>



                            <h6>Dr. Jyotika Kapoor</h6>
                            <StarIcon className='client_logo' /> <StarIcon className='client_logo' /><StarIcon className='client_logo' /><StarIcon className='client_logo' /><StarIcon className='client_logo' />
                            <p className="description">
                                Best Quality product in the NFC barcode standy with unique creative designs. They customize as per the our business standy Thank You.
                            </p>
                        </div>

                    </div>

                    <div className="testimonial">
                        <div className="testimonial-content">
                            <div className="testimonial-icon">
                                <i className="fa fa-quote-left"></i>
                            </div>

                            <div className='img'>
                                <img src={p3} alt='here' style={{ width: '150px', margin: 'auto', borderRadius: '50%' }} className='img-fluid' />
                            </div>
                            <h6>Khushi Patel</h6>
                            <StarIcon className='client_logo' /> <StarIcon className='client_logo' /><StarIcon className='client_logo' /><StarIcon className='client_logo' /><StarIcon className='client_logo' />
                            <p className="description">
                                Amazing product and service. Very happy for the product provided by CustomCliQ. Very nice service and communication with the team.
                            </p>
                        </div>

                    </div>
                </OwlCarousel>
            </div>
        </div>
    );
}

export default Customer_review;

