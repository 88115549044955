import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faSquareInstagram } from '@fortawesome/free-brands-svg-icons';


import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import newlogo from '../src/img/new_logo.png'
const Footer = () => {
    return (
        <>
            <div className='container-fluid main_footer' style={{ backgroundColor: '#132b43' }} >
                <div className='row about_footer text-white' >

                    <div className='col-lg-3 col-md-6 col-sm-12 first_footer about_footer1' style={{ height: '400px' }}>
                        <h4>CustomCliQ</h4>
                        {/* <img src={newlogo} alt='here' className='img-fluid' style={{width:'100px'}} /> */}
                        {/* <p>We are here to simplify the way you connect with people & potential leads. Our solutions are NFC powered & backed by our smart app. Click here to know more about us.</p> */}
                        <p>Address: 122, Times shoppers,<br /> near Amiras Hotel, <br /> Sarthana Jakatnaka, Surat - 395013

                        </p>
                        <h6 style={{ fontSize: '17px' }}>Email : customcliq@gmail.com</h6>
                        <div className='social_media pt-3 d-flex'>
                            <a href='https://www.facebook.com/customcliq' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <FacebookIcon style={{ fontSize: '36' }} /></a>
                            <a href='https://www.instagram.com/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <FontAwesomeIcon icon={faSquareInstagram} style={{ fontSize: '36', marginLeft: '1rem' }} /> </a>
                            <a href='https://wa.me/919054051551' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>            <FontAwesomeIcon icon={faSquareWhatsapp} style={{ fontSize: '36', marginLeft: '1rem' }} /> </a>
                            <a href='https://www.linkedin.com/company/customcliq/' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}> <LinkedInIcon style={{ fontSize: '36', marginLeft: '1rem' }} />    </a>
                        </div>
                    </div>

                    <div className='col-lg-2 col-md-6 col-sm-12 second_footer' style={{ height: '400px', paddingLeft: '1rem' }}>
                        <h4 style={{ paddingLeft: '2rem' }}>Quick Links</h4>
                        <ul style={{ paddingTop: '1rem' }}>
                            <li><a href='#'>  Compatible Devices   </a></li>
                            <li><a href='#'>  Bulk Orders   </a></li>
                            <li><a href='#'>  Corporate Orders   </a></li>
                            <li><a href='#'>  Blogs   </a></li>
                           
                        </ul>
                    </div>

                    <div className='col-lg-2 col-md-6 col-sm-12 second_footer ' style={{ height: '400px' }}>
                        <h4 style={{ paddingLeft: '2rem' }}> Products </h4>

                        <ul style={{ paddingTop: '1rem' }}>
                            <li><a href='#'>  All Products  </a></li>
                            <li><a href='#'>  Scube PVC - Smart Business Card   </a></li>
                            <li><a href='#'>  Scube Metal - Smart Business Card   </a></li>
                            <li><a href='#'>  Scube Pops   </a></li>
                            
                        </ul>
                    </div>



                    <div className='col-lg-2 col-md-6 col-sm-12 second_footer' style={{ height: '400px', paddingLeft: '1rem' }}>
                        <h4 style={{ paddingLeft: '2rem' }}>Support</h4>
                        <ul style={{ paddingTop: '1rem' }}>
                            <li><a href='#'>  FAQ's   </a></li>
                            <li><a href='#'>  Support   </a></li>
                            <li><a href='#'>  Track Orders  </a></li>
                            <li><a href='#'>  How it works   </a></li>
                           
                        </ul>
                    </div>

                    <div className='col-lg-2 col-md-6 col-sm-12 second_footer' style={{ height: '400px', paddingLeft: '1rem' }}>
                        <h4 style={{ paddingLeft: '2rem' }}>Legal</h4>
                        <ul style={{ paddingTop: '1rem' }}>
                            <li><a href='#'>  Privacy Policy   </a></li>
                            <li><a href='#'>  Cookie Policy   </a></li>
                            <li><a href='#'>  Terms & Conditions  </a></li>
                            <li><a href='#'>  Shipping & Return Policy   </a></li>
                           
                        </ul>
                    </div>




                    {/* footer last line----------- */}

                    <div class="card-footer text-white text-center mt-4" style={{ background: '#49a0e3', lineHeight: '2.50', fontSize: '17px', letterSpacing: '1px' }}>
                        © 2024, CustomCliQ.  All rights reserved. Powered By CustomCliQ Pvt Ltd
                    </div>
                </div>












            </div>

        </>
    )
}


export default Footer;