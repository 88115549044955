

import React, { useState } from 'react';
import Navbar from '../Navbar';

import WhatsAppButton from './WhatsAppButton';

import './product.css';
import { useParams, useNavigate } from 'react-router-dom';

import MyCarousel from './My_Carousal';
import metal1 from '../img/cards/c1.png';
import metal2 from '../img/cards/c2.png';
import metal3 from '../img/cards/T_C.png';
import metal4 from '../img/cards/D_c.png';
import pvc1 from '../img/cards/pvc1.jpeg';
import pvc2 from '../img/cards/pvc2.png';
import pvc3 from '../img/cards/pvc3.png';
import pvc4 from '../img/cards/pvc4.png';
import en1 from '../img/cards/tour.png';
import ad from '../img/cards/ad.png';
import ca from '../img/cards/ca.png';
import doctor from '../img/cards/doctor.png';
import p1 from '../img/cards/p1.png';
import p2 from '../img/cards/p2.png';
import p3 from '../img/cards/p3.png';
import p4 from '../img/cards/P4.png';
import i1 from '../img/cards/i1.png';
import i2 from '../img/cards/i2.png';
import i3 from '../img/cards/i3.png';
import i4 from '../img/cards/i4.png';
import r1 from '../img/cards/r1.png';
import r2 from '../img/cards/r2.png';
import r3 from '../img/cards/r3.png';
import r4 from '../img/cards/r4.png';

import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';


const cardData = [

    { id: 1, image: metal1, title: 'Classy Black - Metal NFC', category: 'Category 1', description: 'Business Visiting Card', dc2: 'This fancy card is made for professionals who want to easily share contact info, social media handles, portfolio, etc. Just tap it on a phone to swap details. It looks sleek and stylish, perfect for any business professionals. The card is made from top-quality metal, with a strong and classy black finish, showing off luxury and exclusivity. It works with most smartphones, fitting right into your digital life. Get the NFC tag business card to make networking smooth and classy.', price: '₹ 1999 ( 60% OFF )', relatedImages: [metal1, metal2, metal3, metal4] },
    { id: 2, image: metal2, title: 'Classy Silver - Metal NFC', category: 'Category 1', description: 'Business Visiting Card', dc2: 'This fancy card is made for professionals who want to easily share contact info, social media handles, portfolio, etc. Just tap it on a phone to swap details. It looks sleek and stylish, perfect for any business professionals. The card is made from top-quality metal, with a strong and classy black finish, showing off luxury and exclusivity. It works with most smartphones, fitting right into your digital life. Get the NFC tag business card to make networking smooth and classy.', price: '₹ 1999 ( 60% OFF )', relatedImages: [metal1, metal2, metal3, metal4] },
    { id: 3, image: metal3, title: 'Travel Black - Metal NFC', category: 'Category 1', description: 'Business Visiting Card', dc2: 'This fancy card is made for professionals who want to easily share contact info, social media handles, portfolio, etc. Just tap it on a phone to swap details. It looks sleek and stylish, perfect for any business professionals. The card is made from top-quality metal, with a strong and classy black finish, showing off luxury and exclusivity. It works with most smartphones, fitting right into your digital life. Get the NFC tag business card to make networking smooth and classy.', price: '₹ 2199 ( 60% OFF )', relatedImages: [metal1, metal2, metal3, metal4] },
    { id: 4, image: metal4, title: 'Doctor Black - Metal NFC', category: 'Category 1', description: 'Business Visiting Card', dc2: 'This fancy card is made for professionals who want to easily share contact info, social media handles, portfolio, etc. Just tap it on a phone to swap details. It looks sleek and stylish, perfect for any business professionals. The card is made from top-quality metal, with a strong and classy black finish, showing off luxury and exclusivity. It works with most smartphones, fitting right into your digital life. Get the NFC tag business card to make networking smooth and classy.', price: '₹ 2199 ( 60% OFF )', relatedImages: [metal1, metal2, metal3, metal4] },

    { id: 5, image: pvc1, title: 'Black Professional - PVC NFC', category: 'Category 2', description: 'Business Visiting Card', dc2: 'This NFC-based business card seamlessly merges classic aesthetics with cutting-edge technology. Our custom NFC cards not only make a memorable impression but also offer convenience with their nfc chip technology. Elevate your networking with business cards with NFC, the epitome of modern interaction. Opt for a smart business card that stands out with its NFC technology business card feature. This digital NFC business card serves as a personalized business card solution for professionals seeking a classy and efficient approach."', price: '₹ 799 ( 52% OFF )', relatedImages: [pvc1, pvc2, pvc3, pvc4] },
    { id: 6, image: pvc2, title: 'Clean Pink - PVC NFC', category: 'Category 2', description: 'Business Visiting Card', dc2: 'This NFC-based business card seamlessly merges classic aesthetics with cutting-edge technology. Our custom NFC cards not only make a memorable impression but also offer convenience with their nfc chip technology. Elevate your networking with business cards with NFC, the epitome of modern interaction. Opt for a smart business card that stands out with its NFC technology business card feature. This digital NFC business card serves as a personalized business card solution for professionals seeking a classy and efficient approach."', price: '₹ 799 ( 52% OFF )', relatedImages: [pvc1, pvc2, pvc3, pvc4] },
    { id: 7, image: pvc3, title: 'Colorful Curve - PVC NFC', category: 'Category 2', description: 'Business Visiting Card', dc2: 'This NFC-based business card seamlessly merges classic aesthetics with cutting-edge technology. Our custom NFC cards not only make a memorable impression but also offer convenience with their nfc chip technology. Elevate your networking with business cards with NFC, the epitome of modern interaction. Opt for a smart business card that stands out with its NFC technology business card feature. This digital NFC business card serves as a personalized business card solution for professionals seeking a classy and efficient approach."', price: '₹ 799 ( 52% OFF )', relatedImages: [pvc1, pvc2, pvc3, pvc4] },
    { id: 8, image: pvc4, title: 'SideLine Blue - PVC NFC', category: 'Category 2', description: 'Business Visiting Card', dc2: 'This NFC-based business card seamlessly merges classic aesthetics with cutting-edge technology. Our custom NFC cards not only make a memorable impression but also offer convenience with their nfc chip technology. Elevate your networking with business cards with NFC, the epitome of modern interaction. Opt for a smart business card that stands out with its NFC technology business card feature. This digital NFC business card serves as a personalized business card solution for professionals seeking a classy and efficient approach."', price: '₹ 799 ( 52% OFF )', relatedImages: [pvc1, pvc2, pvc3, pvc4] },

    { id: 9, image: en1, title: 'Tour N Travel Engraved - Metal NFC', category: 'Category 3', description: 'Business Visiting Card', dc2: 'Upgrade how you connect with others with our smart NFC business card by CustomCliQ. This visiting card is made for professionals who want to easily share contact info, social media handles, portfolio, or collect payment, etc. Just tap it on a phone or scan QR code to share details quickly. It looks sleek and stylish, perfect for any business professionals. The card is made from top-quality material, with a strong and classy matt finish, showing off luxury and exclusivity. It works with most smartphones, fitting right into your digital life. Get the NFC tag business card to make networking smooth and effective.', price: '₹ 2499 ( 55% OFF )', relatedImages: [en1, ca, ad, doctor] },
    { id: 10, image: ca, title: 'CA Engraved - Metal NFC', category: 'Category 3', description: 'Business Visiting Card', dc2: 'Upgrade how you connect with others with our smart NFC business card by CustomCliQ. This visiting card is made for professionals who want to easily share contact info, social media handles, portfolio, or collect payment, etc. Just tap it on a phone or scan QR code to share details quickly. It looks sleek and stylish, perfect for any business professionals. The card is made from top-quality material, with a strong and classy matt finish, showing off luxury and exclusivity. It works with most smartphones, fitting right into your digital life. Get the NFC tag business card to make networking smooth and effective.', price: '₹ 2499 ( 55% OFF )', relatedImages: [en1, ca, ad, doctor] },
    { id: 11, image: ad, title: 'Advocate Engraved - Metal NFC', category: 'Category 3', description: 'Business Visiting Card', dc2: 'Upgrade how you connect with others with our smart NFC business card by CustomCliQ. This visiting card is made for professionals who want to easily share contact info, social media handles, portfolio, or collect payment, etc. Just tap it on a phone or scan QR code to share details quickly. It looks sleek and stylish, perfect for any business professionals. The card is made from top-quality material, with a strong and classy matt finish, showing off luxury and exclusivity. It works with most smartphones, fitting right into your digital life. Get the NFC tag business card to make networking smooth and effective.', price: '₹ 2499 ( 55% OFF )', relatedImages: [en1, ca, ad, doctor] },
    { id: 12, image: doctor, title: 'Doctor Engraved - Metal NFC', category: 'Category 3', description: 'Business Visiting Card', dc2: 'Upgrade how you connect with others with our smart NFC business card by CustomCliQ. This visiting card is made for professionals who want to easily share contact info, social media handles, portfolio, or collect payment, etc. Just tap it on a phone or scan QR code to share details quickly. It looks sleek and stylish, perfect for any business professionals. The card is made from top-quality material, with a strong and classy matt finish, showing off luxury and exclusivity. It works with most smartphones, fitting right into your digital life. Get the NFC tag business card to make networking smooth and effective.', price: '₹ 2499 ( 55% OFF )', relatedImages: [en1, ca, ad, doctor] },

    { id: 13, image: p1, title: 'CA H Blue - PVC NFC', category: 'Category 4', description: 'Business Visiting Card', dc2: 'Upgrade how you connect with others with our smart NFC business card by CustomCliQ. This visiting card is made for professionals who want to easily share contact info, social media handles, portfolio, or collect payment, etc. Just tap it on a phone or scan QR code to share details quickly. It looks sleek and stylish, perfect for any business professionals. The card is made from top-quality material, with a strong and classy matt finish, showing off luxury and exclusivity. It works with most smartphones, fitting right into your digital life. Get the NFC tag business card to make networking smooth and effective.', price: '₹ 899 ( 45% OFF )', relatedImages: [p1, p2, p3, p4] },
    { id: 14, image: p2, title: 'Doctor Man - PVC NFC', category: 'Category 4', description: 'Business Visiting Card', dc2: 'Upgrade how you connect with others with our smart NFC business card by CustomCliQ. This visiting card is made for professionals who want to easily share contact info, social media handles, portfolio, or collect payment, etc. Just tap it on a phone or scan QR code to share details quickly. It looks sleek and stylish, perfect for any business professionals. The card is made from top-quality material, with a strong and classy matt finish, showing off luxury and exclusivity. It works with most smartphones, fitting right into your digital life. Get the NFC tag business card to make networking smooth and effective.', price: '₹ 899 ( 45% OFF )', relatedImages: [p1, p2, p3, p4] },
    { id: 15, image: p3, title: 'Freelancer Abstract - PVC', category: 'Category 4', description: 'Business Visiting Card', dc2: 'Upgrade how you connect with others with our smart NFC business card by CustomCliQ. This visiting card is made for professionals who want to easily share contact info, social media handles, portfolio, or collect payment, etc. Just tap it on a phone or scan QR code to share details quickly. It looks sleek and stylish, perfect for any business professionals. The card is made from top-quality material, with a strong and classy matt finish, showing off luxury and exclusivity. It works with most smartphones, fitting right into your digital life. Get the NFC tag business card to make networking smooth and effective.', price: '₹ 899 ( 45% OFF )', relatedImages: [p1, p2, p3, p4] },
    { id: 16, image: p4, title: 'Architect Pink - PVC NFC', category: 'Category 4', description: 'Business Visiting Card', dc2: 'Upgrade how you connect with others with our smart NFC business card by CustomCliQ. This visiting card is made for professionals who want to easily share contact info, social media handles, portfolio, or collect payment, etc. Just tap it on a phone or scan QR code to share details quickly. It looks sleek and stylish, perfect for any business professionals. The card is made from top-quality material, with a strong and classy matt finish, showing off luxury and exclusivity. It works with most smartphones, fitting right into your digital life. Get the NFC tag business card to make networking smooth and effective.', price: '₹ 899 ( 45% OFF )', relatedImages: [p1, p2, p3, p4] },

    { id: 17, image: i1, title: 'Line Blue - PVC NFC ', category: 'Category 5', description: 'Card for Influencer', dc2: 'Stand out from the crowd and elevate your networking game with CustomCliQ NFC Influencer Business Card. Our NFC smart business card equipped with Near Field Communication (NFC) technology, simply tap the card against any NFC-enabled device, and watch as it seamlessly connects the recipient to your digital portfolio, social media profiles, website, and Share your contact information to them. Its minimalist design ensures that your information remains the focal point, leaving a memorable impression on potential clients, partners, and collaborators. This NFC tag business card serves as a portal to your online presence in addition to being a piece of paper.', price: '₹ 499 ( 67% OFF )', relatedImages: [i1, i2, i3, i4] },
    { id: 18, image: i2, title: 'Emoji Shade - PVC NFC', category: 'Category 5', description: 'Card for Influencer', dc2: 'Stand out from the crowd and elevate your networking game with CustomCliQ NFC Influencer Business Card. Our NFC smart business card equipped with Near Field Communication (NFC) technology, simply tap the card against any NFC-enabled device, and watch as it seamlessly connects the recipient to your digital portfolio, social media profiles, website, and Share your contact information to them. Its minimalist design ensures that your information remains the focal point, leaving a memorable impression on potential clients, partners, and collaborators. This NFC tag business card serves as a portal to your online presence in addition to being a piece of paper.', price: '₹ 499 ( 67% OFF )', relatedImages: [i1, i2, i3, i4] },
    { id: 19, image: i3, title: 'Mud Grey - PVC NFC ', category: 'Category 5', description: 'Card for Influencer', dc2: 'Stand out from the crowd and elevate your networking game with CustomCliQ NFC Influencer Business Card. Our NFC smart business card equipped with Near Field Communication (NFC) technology, simply tap the card against any NFC-enabled device, and watch as it seamlessly connects the recipient to your digital portfolio, social media profiles, website, and Share your contact information to them. Its minimalist design ensures that your information remains the focal point, leaving a memorable impression on potential clients, partners, and collaborators. This NFC tag business card serves as a portal to your online presence in addition to being a piece of paper.', price: '₹ 499 ( 67% OFF )', relatedImages: [i1, i2, i3, i4] },
    { id: 20, image: i4, title: 'Dream Black - PVC NFC', category: 'Category 5', description: 'Card for Influencer', dc2: 'Stand out from the crowd and elevate your networking game with CustomCliQ NFC Influencer Business Card. Our NFC smart business card equipped with Near Field Communication (NFC) technology, simply tap the card against any NFC-enabled device, and watch as it seamlessly connects the recipient to your digital portfolio, social media profiles, website, and Share your contact information to them. Its minimalist design ensures that your information remains the focal point, leaving a memorable impression on potential clients, partners, and collaborators. This NFC tag business card serves as a portal to your online presence in addition to being a piece of paper.', price: '₹ 499 ( 67% OFF )', relatedImages: [i1, i2, i3, i4] },

    { id: 21, image: r1, title: 'Google Review Black - PVC NFC', category: 'Category 6', description: 'Business Review Card', dc2: 'The Google Review Card represents a major advancement in gathering customer feedback. This innovative card leverages NFC technology to enable customers to conveniently provide their thoughts with a simple tap of their phone. Rather than navigating through various screens, this card streamlines the process with its QR code capabilities, guaranteeing compatibility with both iOS and Android devices. This tool is critical for any business focused on quickly improving its online reputation. With no hidden costs and complimentary same-day shipping, the Google Review Card is an accessible and budget-friendly solution.', price: '₹ 599 ( 60% OFF )', relatedImages: [r1, r2, r3, r4] },
    { id: 22, image: r2, title: 'Google Review Circle - PVC NFC', category: 'Category 6', description: 'Business Review Card', dc2: 'The Google Review Card represents a major advancement in gathering customer feedback. This innovative card leverages NFC technology to enable customers to conveniently provide their thoughts with a simple tap of their phone. Rather than navigating through various screens, this card streamlines the process with its QR code capabilities, guaranteeing compatibility with both iOS and Android devices. This tool is critical for any business focused on quickly improving its online reputation. With no hidden costs and complimentary same-day shipping, the Google Review Card is an accessible and budget-friendly solution.', price: '₹ 599 ( 60% OFF )', relatedImages: [r1, r2, r3, r4] },
    { id: 23, image: r3, title: 'Google Review Plain - PVC NFC', category: 'Category 6', description: 'Business Review Card', dc2: 'The Google Review Card represents a major advancement in gathering customer feedback. This innovative card leverages NFC technology to enable customers to conveniently provide their thoughts with a simple tap of their phone. Rather than navigating through various screens, this card streamlines the process with its QR code capabilities, guaranteeing compatibility with both iOS and Android devices. This tool is critical for any business focused on quickly improving its online reputation. With no hidden costs and complimentary same-day shipping, the Google Review Card is an accessible and budget-friendly solution.', price: '₹ 599 ( 60% OFF )', relatedImages: [r1, r2, r3, r4] },
    { id: 24, image: r4, title: 'Google Review White - PVC NFC', category: 'Category 6', description: 'Business Review Card', dc2: 'The Google Review Card represents a major advancement in gathering customer feedback. This innovative card leverages NFC technology to enable customers to conveniently provide their thoughts with a simple tap of their phone. Rather than navigating through various screens, this card streamlines the process with its QR code capabilities, guaranteeing compatibility with both iOS and Android devices. This tool is critical for any business focused on quickly improving its online reputation. With no hidden costs and complimentary same-day shipping, the Google Review Card is an accessible and budget-friendly solution.', price: '₹ 599 ( 60% OFF )', relatedImages: [r1, r2, r3, r4] },
];



const Nfc_product = () => {
    const { id } = useParams();  // Get the product ID from the URL params

    const navigate = useNavigate();

    const card = cardData.find(c => c.id === parseInt(id, 10));  // Find the matching card

    const [mainImage, setMainImage] = useState(card.mainImage || card.image);

    // Find the card by ID

    if (!card) {
        return <p>Card not found</p>;
    }

    const handleAddToCart = () => {
        // Navigate to cart with product details as URL parameters
        navigate(`/cart?title=${card.title}&price=${card.price}&image=${card.image}&description=${card.description}`);
    };



    return (
        <>
            {/* navbar */}
            <Navbar />


            {/* NFC_product start------------*/}
            <div class="main">
                <div class="content12" >
                    <h1>Get Your Private <span style={{ color: '#49a0e3' }}> Business NFC Card </span> <br /> with a Promissory</h1>
                    {/* <p>This content is centered both vertically and horizontally.</p> */}
                </div>
            </div>

            <div className="container main_nfc_product mt-5 pt-5">
                <div className="row" >

                    {/* Right side: main image and details */}
                    <div className="col-lg-5 col-md-5 col-sm-12  " style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}>
                        <div className="card text-center pb-5 pt-5 border-0 "   >
                            <img src={mainImage} className="img_ card-img-top img-fluid mx-auto" alt={card.title} />
                        </div>

                        {/* small thumbnails - 4 */}
                        <div className="col d-flex mt-3 justify-content-evenly">
                            {card.relatedImages?.map((image, index) => (
                                <img
                                    key={index}
                                    src={image}
                                    alt="Thumbnail"
                                    onClick={() => setMainImage(image)}
                                    className="img-thumbnail mb-2"
                                    style={{ cursor: 'pointer', width: '100px', height: '100px' }}
                                />
                            ))}
                        </div>
                    </div>

                    <div className='col-lg-6 col-md-6 col-sm-12 pt-3 '>
                        <div className='main_cards'>
                            <div className="card_body">
                                <h5 className="card-title">{card.title}</h5>

                                <p className="p_main card-text">{card.description}</p>

                                <hr style={{ width: '80%', margin: 'auto' }} />

                                <p className="desc card-text">{card.dc2}</p>

                                <h6 className='text-center' style={{ color: 'orange' }}>
                                    <StarIcon style={{ fontSize: '35px' }} /> <StarIcon style={{ fontSize: '35px' }} /> <StarIcon style={{ fontSize: '35px' }} /> <StarIcon style={{ fontSize: '35px' }} /> <StarHalfIcon style={{ fontSize: '35px' }} />
                                </h6>

                                <p className="text-center price12">{card.price}</p>

                                {/* whatsappBTN */}
                                <WhatsAppButton productName={card.title}
                                    description={card.description}
                                    price={card.price}
                                    pageUrl={`http://localhost:3000/product/${card.id}`} />

                                <button type="button" className="btn btn-lg ms-5 mt-2 " style={{ background: '#132b43', color: 'white', fontSize: '18px' }}>For More Information</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* nfc card carousal  */}
            <MyCarousel />
        </>
    );
};

export default Nfc_product;
