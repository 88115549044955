import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';  // Import useNavigate

import 'bootstrap/dist/css/bootstrap.min.css';

import './product/NFC_Pro';

import { NavLink } from 'react-router-dom';

import Navbar from './Navbar';
import Footer from './Footer';

import metal1 from './img/cards/c1.png';
import metal2 from './img/cards/c2.png';
import metal3 from './img/cards/T_C.png';
import metal4 from './img/cards/D_c.png';

import pvc1 from './img/cards/pvc1.png'
import pvc2 from './img/cards/pvc2.png'
import pvc3 from './img/cards/pvc3.png'
import pvc4 from './img/cards/pvc4.png'

import en1 from './img/cards/tour.png';
import ad from './img/cards/ad.png';
import ca from './img/cards/ca.png';
import doctor from './img/cards/doctor.png';

import p1 from './img/cards/p1.png';
import p2 from './img/cards/p2.png';
import p3 from './img/cards/p3.png';
import p4 from './img/cards/P4.png';
import i1 from './img/cards/i1.png';
import i2 from './img/cards/i2.png';
import i3 from './img/cards/i3.png';
import i4 from './img/cards/i4.png';
import r1 from './img/cards/r1.png';
import r2 from './img/cards/r2.png';
import r3 from './img/cards/r3.png';
import r4 from './img/cards/r4.png';

import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';


const cardData = [
    { id: 1, image: metal1, title: 'Classy Black - Metal NFC', category: 'Category 1', description: 'Business Visiting Card', price: '₹ 1999 ( 60% OFF )',quantity:1 },
    { id: 2, image: metal2, title: 'Classy Silver - Metal NFC', category: 'Category 1', description: 'Business Visiting Card', price: '₹ 1999 ( 60% OFF )',quantity:1 },
    { id: 3, image: metal3, title: 'Travel Black - Metal NFC', category: 'Category 1', description: 'Business Visiting Card', price: '₹ 2199 ( 60% OFF )',quantity:1 },

    { id: 4, image: metal4, title: 'Doctor Black - Metal NFC', category: 'Category 1', description: 'Business Visiting Card', price: '₹ 2199 ( 60% OFF )',quantity:1 },

    { id: 5, image: pvc1, title: 'Black Professional - PVC NFC', category: 'Category 2', description: 'Business Visiting Card', price: '₹ 799 ( 52% OFF )',quantity:1 },

    { id: 6, image: pvc2, title: 'Clean Pink - PVC NFC', category: 'Category 2', description: 'Business Visiting Card', price: '₹ 799 ( 52% OFF )',quantity:1 },

    { id: 7, image: pvc3, title: 'Colorful Curve - PVC NFC', category: 'Category 2', description: 'Business Visiting Card', price: '₹ 799 ( 52% OFF )',quantity:1 },

    { id: 8, image: pvc4, title: 'SideLine Blue - PVC NFC', category: 'Category 2', description: 'Business Visiting Card', price: '₹ 799 ( 52% OFF )',quantity:1 },

    { id: 9, image: en1, title: 'Tour N Travel Engraved - Metal NFC', category: 'Category 3', description: 'Business Visiting Card', price: '₹ 2499 ( 55% OFF )',quantity:1 },

    { id: 10, image: ca, title: 'CA Engraved - Metal NFC', category: 'Category 3', description: 'Business Visiting Card', price: '₹ 2499 ( 55% OFF )',quantity:1 },

    { id: 11, image: ad, title: 'Advocate Engraved - Metal NFC', category: 'Category 3', description: 'Business Visiting Card', price: '₹ 2499 ( 55% OFF )',quantity:1 },

    { id: 12, image: doctor, title: 'Doctor Engraved - Metal NFC', category: 'Category 3', description: 'Business Visiting Card', price: '₹ 2499 ( 55% OFF )',quantity:1 },

    { id: 13, image: p1, title: 'CA H Blue - PVC NFC', category: 'Category 4', description: 'Business Visiting Card', price: '₹ 899 ( 45% OFF )',quantity:1 },

    { id: 14, image: p2, title: 'Doctor Man - PVC NFC', category: 'Category 4', description: 'Business Visiting Card', price: '₹ 899 ( 45% OFF )',quantity:1 },

    { id: 15, image: p3, title: 'Freelancer Abstract - PVC', category: 'Category 4', description: 'Business Visiting Card', price: '₹ 899 ( 45% OFF )',quantity:1 },

    { id: 16, image: p4, title: 'Architect Pink - PVC NFC', category: 'Category 4', description: 'Business Visiting Card', price: '₹ 899 ( 45% OFF )',quantity:1 },

    { id: 17, image: i1, title: 'Line Blue - PVC NFC ', category: 'Category 5', description: 'Card for Influencer', price: '₹ 499 ( 67% OFF )',quantity:1 },

    { id: 18, image: i2, title: 'Emoji Shade - PVC NFC', category: 'Category 5', description: 'Card for Influencer', price: '₹ 499 ( 67% OFF )',quantity:1 },

    { id: 19, image: i3, title: 'Mud Grey - PVC NFC ', category: 'Category 5', description: 'Card for Influencer', price: '₹ 499 ( 67% OFF )',quantity:1 },

    { id: 20, image: i4, title: 'Dream Black - PVC NFC', category: 'Category 5', description: 'Card for Influencer', price: '₹ 499 ( 67% OFF )',quantity:1 },

    { id: 21, image: r1, title: 'Google Review Black - PVC NFC', category: 'Category 6', description: 'Business Review Card', price: '₹ 599 ( 60% OFF )',quantity:1 },

    { id: 22, image: r2, title: 'Google Review Circle - PVC NFC', category: 'Category 6', description: 'Business Review Card', price: '₹ 599 ( 60% OFF )',quantity:1 },

    { id: 23, image: r3, title: 'Google Review Plain - PVC NFC', category: 'Category 6', description: 'Business Review Card', price: '₹ 599 ( 60% OFF )',quantity:1 },

    { id: 24, image: r4, title: 'Google Review White - PVC NFC', category: 'Category 6', description: 'Business Review Card', price: '₹ 599 ( 60% OFF )',quantity:1 },
];



const All_Type_Card = () => {

    const [cartItems, setCartItems] = useState([]);

    // const addToCart = (card) => {
    //     setCartItems([...cartItems, card]);
    //     navigate('/cart', { state: { cartItems: [...cartItems, card] } }); // Navigate to the Add_Cart page with cart items
    // };
    const addToCart = (card) => {
        // Retrieve existing cart items from localStorage
        const existingCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
        
        // Add the new item to the existing list
        const updatedCartItems = [...existingCartItems, card];
        
        // Update the state and localStorage with the new list
        setCartItems(updatedCartItems);
        localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    
        // Navigate to the cart page with the updated items
        navigate('/cart', { state: { cartItems: updatedCartItems } });
    };
    

    const [activeCategory, setActiveCategory] = useState('All');

    const navigate = useNavigate();  // Initialize useNavigate

    const handleAddToCart = (id) => {
        navigate(`/product/${id}`);  // Navigate to the new page with the selected card's id
    };

    const filteredCards = activeCategory === 'All'
        ? cardData
        : cardData.filter(card => card.category === activeCategory);


    return (
        <>
            {/* navbar */}
            <div className='navbar'> <Navbar /> </div>


            <div className='container main_All_card pt-5 '>
                <h4 style={{ color: '#132b43' }}>Smart NFC Business Visiting Card</h4>

                {/* Filter Buttons */}
                <div className="button-group mt-4">
                    <button onClick={() => setActiveCategory('All')}>        All </button>
                    <button onClick={() => setActiveCategory('Category 1')}> Metal  </button>
                    <button onClick={() => setActiveCategory('Category 2')}> PVC    </button>
                    <button onClick={() => setActiveCategory('Category 3')}> Engraved Card </button>
                    <button onClick={() => setActiveCategory('Category 4')}> Professional </button>
                    <button onClick={() => setActiveCategory('Category 5')}> Influencer Card </button>
                    <button onClick={() => setActiveCategory('Category 6')}> Review Card </button>
                </div>


                {/* Display Cards */}

                {/* Your other JSX content */}
                <div className="row" style={{ marginTop: '4rem' }}>
                    {filteredCards.map(card => (
                        <div key={card.id} className="col-lg-3 col-md-6 col-sm-12 mb-4 card" >
                            <div className="card1 h-100 w-100">
                                <div className="card-body">
                                <div className='d-flex justify-content-center'>
                                    <img src={card.image} className="card-img-top img-fluid" alt='here-image' style={{ maxWidth: '95%', height: 'auto' }} />
                                    </div>
                                    <h5 className="card-title">{card.title}</h5>
                                    <p className="card-text">{card.description}</p>
                                    <h6 className='text-center' style={{ color: 'orange', fontSize: '6px' }}>
                                        <StarIcon /> <StarIcon /> <StarIcon /> <StarIcon /> <StarHalfIcon />
                                    </h6>
                                    <p className="text1"> {card.price} </p>

                                    <div className='btn12 d-flex justify-content-center'>
                                        <button
                                            type="button"
                                            className="btn btn1 btn-sm text-white"
                                            style={{ background: '#132b43' }}
                                            onClick={() => addToCart(card)}
                                        >
                                            Add To Cart
                                        </button>

                                        
                                        <button
                                            type="button"
                                            className="btn btn-sm btn2 ms-3"
                                            style={{ background: 'white', border: '1px solid black' }}
                                            onClick={() => navigate(`/product/${card.id}`)}  // Navigate to product page
                                        >
                                            Buy Now
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>




                {/* content */}

                <div className='main_content1' style={{ marginTop: '4rem' }}>
                    <div className='con1'>
                        <h5>Buy Engraved NFC Business Card</h5>
                        <p className='pt-4'>Discover elegance and innovation with Rich Kardz's Engraved NFC business card. These magnificent cards demonstrate the flawless marriage of craftsmanship and modern technologies. Each card is precisely engraved and made of high-quality materials, providing a physical experience that digital communication frequently lacks. But it's not just about aesthetics; state-of-the-art NFC technology is embedded within these finely crafted cards.</p>

                        <p>This feature transforms the traditional business card into a powerful networking tool, enabling the instant sharing of contact information, websites, or portfolios with a simple tap against any NFC-enabled device. The combination of intricate engraving and cutting-edge technology makes the Rich Kardz Engraved NFC smart business card a must-have for professionals.</p>

                        <h5 className='pt-5'>Engraved NFC Cards for Seamless Connections</h5>

                        <p className='pt-4'>NFC business cards are a mixture of traditional ways of networking with modern technology which is a dynamic tool for many business professionals. Our embossed NFC business cards are made up of high-quality material and embedded with an NFC chip inside to enable seamless data exchange between individuals. Why are people choosing them? It has several advantages or reasons to make it a choice over old paper visiting cards:</p>

                        <p className='pt-3'><b>Instant Contact Sharing:</b> You can share your contact information to another person in an instant with a simple tap.</p>

                        <p className='pt-3'><b>Enhanced Branding:</b> Our engraved NFC business card comes with sleek design which lends sophistication and professionalism that make your brand appearance more elegant and stand out.</p>

                        <p className='pt-3'><b>Interactive Marketing:</b>You can link your websites, portfolio, or even social media profile with these cards which provide recipients an instant interaction with your brand.</p>

                        <p className='pt-3'><b>Eco-friendly:</b> These cards can reduce the wastage of papers and environmental footprints which aligns with eco-conscious initiatives.</p>

                    </div>

                    <div className='con1 pt-5 mb-5 pb-5'>
                        <h5>What Makes Our Business Cards Stand Out?</h5>

                        <p className='pt-3'>Rich Kardz is the well known brand which provides outstanding embossed NFC business cards that helps you to share contact information with a single tap. You can rely on us with all your might because we care for your brand's image. That’s why we pay attention to the quality of our every NFC based business cards:</p>

                        <p className='pt-2'><b>Premium Quality: </b>Crafted with high-grade materials, our cards boast durability and elegance.</p>

                        <p className='pt-2'><b>Unique Designs:</b>From sleek minimalist styles to eye-catching graphics, our designs captivate attention.</p>

                        <p className='pt-2'><b>Personalization: </b>Each card is a unique representation of your company, made to match your corporate identity.</p>

                        <p className='pt-2'><b>Eco-Friendly Options: </b>We provide environment friendly options without sacrificing quality because we embrace sustainability.</p>

                        <p className='pt-2'><b>Cutting-Edge Technology: </b>Utilizing the latest printing techniques, our cards feature crisp details and vibrant colors with the latest NFC technology.</p>

                        <p className='pt-2'><b>Memorable Impressions: </b>Leave a lasting impact with our unforgettable cards, ensuring your brand stays top-of-mind.</p>
                    </div>
                </div>



            </div>



            {/* footer */}
            <div className='footer'>
                <Footer />
            </div>


        </>
    )
}

export default All_Type_Card