import React, { useState } from 'react';
import Navbar from '../Navbar';

import './product.css';
import { useNavigate } from 'react-router-dom';

import MyCarousel from './My_Carousal';

import metal1 from '../img/cards/c1.png';
import metal2 from '../img/cards/c2.png';
import metal3 from '../img/cards/T_C.png';
import metal4 from '../img/cards/D_c.png';
import pvc1 from '../img/cards/pvc1.jpeg';
import pvc2 from '../img/cards/pvc2.png';
import pvc3 from '../img/cards/pvc3.png';
import pvc4 from '../img/cards/pvc4.png';
import instagram1 from '../img/cards/i1.png';
import instagram2 from '../img/cards/i2.png';
import instagram3 from '../img/cards/i3.png';
import instagram4 from '../img/cards/i4.png';

import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';

// Define the data for each category
const productCategories = {
    Instagram: [
        { id: 1, image: instagram1, title: 'Instagram PVC NFC', description: 'Business Card for Influencers', price: '₹ 499 ( 67% OFF )', relatedImages: [instagram1, instagram2, instagram3, instagram4] },
        { id: 2, image: instagram2, title: 'Instagram Card NFC', description: 'Another Instagram NFC Card', price: '₹ 499 ( 67% OFF )', relatedImages: [instagram1, instagram2, instagram3, instagram4] },
    ],
    Wooden: [
        { id: 1, image: pvc1, title: 'Wooden Black - PVC NFC', description: 'Wooden Business Card', price: '₹ 799 ( 52% OFF )', relatedImages: [pvc1, pvc2, pvc3, pvc4] },
        { id: 2, image: pvc2, title: 'Wooden Curve - PVC NFC', description: 'Another Wooden NFC Card', price: '₹ 799 ( 52% OFF )', relatedImages: [pvc1, pvc2, pvc3, pvc4] },
    ],
    Metal: [
        { id: 1, image: metal1, title: 'Classy Black - Metal NFC', description: 'Metal Business Card', price: '₹ 1999 ( 60% OFF )', relatedImages: [metal1, metal2, metal3, metal4] },
        { id: 2, image: metal2, title: 'Classy Silver - Metal NFC', description: 'Another Metal NFC Card', price: '₹ 1999 ( 60% OFF )', relatedImages: [metal1, metal2, metal3, metal4] },
    ],
};

const Sample_Product = () => {
    const [category, setCategory] = useState('Instagram'); // Default to Instagram category
    const [mainImage, setMainImage] = useState(productCategories[category][0].image); // Set initial main image

    const navigate = useNavigate();

    // Handle category change
    const handleCategoryChange = (newCategory) => {
        setCategory(newCategory);
        setMainImage(productCategories[newCategory][0].image); // Set main image for the new category
    };

    const handleAddToCart = (product) => {
        navigate(`/cart?title=${product.title}&price=${product.price}&image=${product.image}&description=${product.description}`);
    };

    return (
        <>
            {/* Navbar */}
            {/* <Navbar /> */}

            {/* Button group for categories */}
            <div className="container text-center mt-5">
            </div>

            {/* Product display based on selected category */}
            <div className="container main_nfc_product mt-5 pt-5">
                <div className="row">
                    {/* Right side: main image and details */}
                    <div className="col-lg-5 col-md-5 col-sm-12">


                        <div className="card text-center pb-5 pt-5 border-0">
                            <img src={mainImage} className="img-fluid mx-auto" alt="Product" />
                        </div>

                        {/* Small thumbnails */}
                        <div className="col d-flex mt-3 justify-content-evenly">
                            {productCategories[category][0].relatedImages.map((image, index) => (
                                <img
                                    key={index}
                                    src={image}
                                    alt="Thumbnail"
                                    onClick={() => setMainImage(image)}
                                    className="img-thumbnail mb-2"
                                    style={{ cursor: 'pointer', width: '100px', height: '100px' }}
                                />
                            ))}
                        </div>
                    </div>

                    {/* Product details */}
                    <div className="col-lg-6 col-md-6 col-sm-12 pt-3">

                        <div className="btn-group d-flex justify-content-between ms-5 me-5 mb-5">
                            <button className="btn btn-dark m-2" onClick={() => handleCategoryChange('Instagram')}>
                               Instagram
                            </button>
                            <button className="btn btn-dark m-2" onClick={() => handleCategoryChange('Wooden')}>
                                WOODEN
                            </button>
                            <button className="btn btn-dark m-2" onClick={() => handleCategoryChange('Metal')}>
                                METAL
                            </button>
                        </div>
                        <div className="card_body">
                            <h5 className="card-title">{productCategories[category][0].title}</h5>
                            <p className="p_main card-text">{productCategories[category][0].description}</p>
                            <hr style={{ width: '80%', margin: 'auto' }} />
                            <h6 className="text-center pt-2" style={{ color: 'orange' }}>
                                <StarIcon style={{ fontSize: '35px' }} /> <StarIcon style={{ fontSize: '35px' }} />{' '}
                                <StarIcon style={{ fontSize: '35px' }} /> <StarIcon style={{ fontSize: '35px' }} />{' '}
                                <StarHalfIcon style={{ fontSize: '35px' }} />
                            </h6>
                            <p className="price12 ">{productCategories[category][0].price}</p>
                            <button
                                type="button"
                                className="btn"
                                style={{ background: '#132b43', color: 'white', fontSize: '18px' }}
                                onClick={() => handleAddToCart(productCategories[category][0])}
                            >
                                Add to Cart
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* NFC card carousel */}
            <MyCarousel />
        </>
    );
};

export default Sample_Product;
