// import React, { createContext, useReducer, useEffect } from 'react';
// import './Cart.css';
// // import {Scrollbars} from 'react-custom-scrollbars';
// // import Items from './Items.js';

// // import arrow from '../img/cards/arrow.png';
// // import cart from '../img/cards/cart.png';



// import {products} from './Products.js';
// import ContextCart from './ContextCart.js';
// import { reducer } from './Reducer.js';
// import './Items.js';



// export const CartContext = createContext();

// const initialState = {
//   item : products,
//   totalAmount : 0,
//   totalItem : 0,
// }



// const Cart = () => {


//   const [state, dispatch] = useReducer(reducer, initialState);


  


//   //remove item
//   const removeItem = (id) => {
//        return dispatch({
//         type: "REMOVE_ITEM",
//         payload: id,
//        })
//   }


//   //clear cart
//   const clearCart =() => {
//     return dispatch({type: "CLEAR_CART" })
//   }


//   // increment
//   const increment = (id) => {
//     return dispatch({
//       type : "INCREMENT",
//       payload : id,
//     })
//   }

//   //decreament
//     const decrement = (id) => {
//     return dispatch({
//       type: "DECREMENT",
//       payload: id,
//     });
//   };


//  // we will use the useEffect to update the data
//  useEffect(() => {
//   dispatch({ type: "GET_TOTAL" });
//   // console.log("Awesome");
// }, [state.item]);  



//   return (
//     <>
       
//        <CartContext.Provider value={{...state, removeItem,  clearCart, increment, decrement}}>

//         <ContextCart />

//        </CartContext.Provider>

//     </>
//   )
// }

// export default Cart;

// import React, { createContext, useReducer, useEffect } from 'react';
// import './Cart.css';
// import { useLocation } from 'react-router-dom';  // Import useLocation to retrieve passed state
// import { reducer } from './Reducer';
// import ContextCart from './ContextCart';

// // Import other necessary files and components

// export const CartContext = createContext();

// const initialState = {
//   item: [],  // Initialize items to an empty array
//   totalAmount: 0,
//   totalItem: 0,
// };

// const Cart = () => {
//   const location = useLocation();
  
//   // Access the passed cart items from location.state (if available)
//   const passedCartItems = location.state?.cartItems || [];

//   const [state, dispatch] = useReducer(reducer, {
//     ...initialState,
//     item: passedCartItems,  // Initialize the cart with passed items
//   });

//   // Remove item
//   const removeItem = (id) => {
//     return dispatch({
//       type: 'REMOVE_ITEM',
//       payload: id,
//     });
//   };

//   // Clear cart
//   const clearCart = () => {
//     return dispatch({ type: 'CLEAR_CART' });
//   };

//   // Increment
//   const increment = (id) => {
//     return dispatch({
//       type: 'INCREMENT',
//       payload: id,
//     });
//   };

//   // Decrement
//   const decrement = (id) => {
//     return dispatch({
//       type: 'DECREMENT',
//       payload: id,
//     });
//   };

//   // UseEffect to update the data
//   useEffect(() => {
//     dispatch({ type: 'GET_TOTAL' });
//   }, [state.item]);

//   return (
//     <>
//       <CartContext.Provider value={{ ...state, removeItem, clearCart, increment, decrement }}>
//         <ContextCart />
//       </CartContext.Provider>
//     </>
//   );
// };

// export default Cart;
import React, { createContext, useReducer, useEffect } from 'react';
import './Cart.css';
import { useLocation } from 'react-router-dom';  // Import useLocation to retrieve passed state
import { reducer } from './Reducer';
import ContextCart from './ContextCart';

// Import other necessary files and components

export const CartContext = createContext();

const initialState = {
  item: [],  // Initialize items to an empty array
  totalAmount: 0,
  totalItem: 0,
};

const Cart = () => {
  const location = useLocation();
  
  // Access the passed cart items from location.state (if available)
  const passedCartItems = location.state?.cartItems || [];

  // Load initial cart items from localStorage (if available)
  const savedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
console.log(savedCartItems)
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    item: savedCartItems.length > 0 ? savedCartItems : passedCartItems,  // Load from localStorage if available
  });

  // Store cart items in localStorage when they change
  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(state.item));
  }, [state.item]);

  // Remove item
  const removeItem = (id) => {
    return dispatch({
      type: 'REMOVE_ITEM',
      payload: id,
    });
  };

  // Clear cart
  const clearCart = () => {
    return dispatch({ type: 'CLEAR_CART' });
    localStorage.removeItem('cartItems');  // Clear localStorage when cart is cleared
  };

  // Increment
  const increment = (id) => {
    return dispatch({
      type: 'INCREMENT',
      payload: id,
    });
  };

  // Decrement
  const decrement = (id) => {
    return dispatch({
      type: 'DECREMENT',
      payload: id,
    });
  };

  // UseEffect to update the total amount and items count
  useEffect(() => {
    dispatch({ type: 'GET_TOTAL' });
  }, [state.item]);

  return (
    <>
      <CartContext.Provider value={{ ...state, removeItem, clearCart, increment, decrement }}>
        <ContextCart />
      </CartContext.Provider>
    </>
  );
};

export default Cart;
