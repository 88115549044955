import React from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const WhatsAppButton = ({ productName, description, price, pageUrl }) => {
  const phoneNumber = '919904464465'; 
  
  const message = `Hello, I am interested in the following product:\n\nProduct Name: ${productName}\nDescription: ${description}\nPrice: ${price}\nLink: ${pageUrl}`;

  const handleClick = () => {
    
    const encodedMessage = encodeURIComponent(message);
   
    window.open(`https://wa.me/${phoneNumber}?text=${encodedMessage}`, '_blank');
  };

  return (  
    <button onClick={handleClick} className="btn btn-lg ms-5 mt-2" style={buttonStyle}>
      <WhatsAppIcon style={{ fontSize: '2rem' }} /> Chat With Us
    </button>
  );
};

// Optional: Add some styles to the button
const buttonStyle = {
  backgroundColor: '#25D366', // WhatsApp green color
  color: 'white',
  border: 'none',
  fontWeight: '600',
  padding: '7px 20px',
  borderRadius: '5px',
  cursor: 'pointer',
  fontSize: '18px',
};

export default WhatsAppButton;
