import React, { useEffect, useState } from "react";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';

import { NavLink } from "react-router-dom";

import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

import ShareImg from '../src/img/share.webp';
import SaveLife from '../src/img/saveLife.webp'

import header from '../src/img/header.png'
import video1 from '../src/img/home/mobile2.webm'


import img1 from '../src/video/Online .gif';
import img2 from '../src/img/home/f2.png';
import img3 from '../src/img/home/f3.png';

import first from '../src/img/NFC Card Mockup/newone.png';
import second from '../src/img/NFC Card Mockup/test.jpg'
import third from '../src/img/NFC Card Mockup/third.png'

import newone from '../src/img/home/hero-img.png';
import logo1 from '../src/img/p1.png';
import logo2 from '../src/img/p2.png';
import logo3 from '../src/img/p3.png';
import logo4 from '../src/img/p4.png';
import logo5 from '../src/img/p5.png';
import logo6 from '../src/img/p6.png';
import logo7 from '../src/img/p7.png';
import logo8 from '../src/img/p8.png';
import fb from '../src/img/NFC Card Mockup/fb.png';
import wpp from '../src/img/NFC Card Mockup/Wp.png';
import gg from '../src/img/NFC Card Mockup/gg.jpg';

import first1 from '../src/img/cards/c1.png';
import first2 from '../src/img/cards/pvc3.png';
import first3 from '../src/img/cards/i1.png';
import first4 from '../src/img/cards/ad.png';

import Customer_review from "./Customer_review.js";

import Cards from "./Cards.js";
import Navbar from "./Navbar.js";
import Footer from "./Footer.js";

import './new.css';



const Home = () => {

  const [isHovered, setIsHovered] = useState(false);


  useEffect(() => {
    AOS.init({ duration: 2000 }); // Initialize AOS and set options
  }, []); // Empty dependency array ensures it runs only once
  return (
    <>

      {/*--------------------- navbar------------------ */}
      <div className="navbar">
        <Navbar />
      </div>

      {/* about page start */}
      <div className=" main_header">
        <div className="bg_img">

          {/* header page card--- */}

          <div className="row  container" style={{ width: '100%', margin: 'auto' }}>
            <div className="col-lg-6 col-md-12  col-sm-12 header " data-aos="fade-right" style={{ marginTop: '12rem' }} position-absolute>

              <h5 className=" text-dark pb-3" >A SMART WAY TO CONNECT</h5>
              <h2 className="fw-bold gradiant">Smart Business Cards</h2>
              <h2 className="fw-bold gradiant">Designed to Impress</h2>
              <p>Introducing the NFC Smart Business Card: Customised designs, superior printing quality, and material, with a two-year replacement guarantee at no extra</p>

              <div className="btn1 d-flex justify-content-center mt-5 w-75">

                <button type="button" class="btn btn-lg btn2  w-50" style={{ background: '#132b43' }}><NavLink to='/card' className='navlink_' style={{ textDecoration: 'none', color: 'white' }} > Explore Cards </NavLink></button>
                <button type="button" class="btn btn-lg  btn2  w-50" style={{ marginLeft: '1rem', background: '#132b43' }}><NavLink to='/works' className='navlink_' style={{ textDecoration: 'none', color: 'white' }} >See How it Works</NavLink></button>

              </div>

            </div>

            <div className="bg_images col-lg-6 col-md-12 col-sm-12  "  >
              {/* <video autoPlay loop muted width="100%" height={700} >
                <source src={video1} type="video/mp4" />
              </video> */}

              <img src={header} className=" img-fluid " alt="i-here" />
            </div>

          </div>
        </div>
      </div>





      <div className="testing bg-black" style={{ paddingTop: '12rem' }}>

      </div>


      {/* ----------------------------first content-------------------- */}


      <div className="video-background-container ">
        <video autoPlay muted loop id="background-video">
          <source src="https://taponn.digital/cdn/shop/videos/c/vp/42811d9cd0884e6e99a1d9e4592c4e84/42811d9cd0884e6e99a1d9e4592c4e84.HD-1080p-2.5Mbps-32611397.mp4?v=0" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Content that appears over the video */}
        {/* <div className="content">
        <h1>Welcome to My Website</h1>
        <p>This is a section with a video background.</p>
      </div> */}
      </div>

      <div className="testing bg-black" style={{ paddingTop: '8rem' }}>

      </div>


      {/* <div className="container-fluid p-0" > */}




      {/* <div className="second_main container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12" style={{ marginTop: '8rem' }}>
              <div className="first_row about_content">
                <h4> <span style={{ color: '#49a0e3' }}> Grow Your Network </span> Wherever You Go</h4>

                <h6>Elevate your professional image with a compelling online presence that boosts your credibility wherever you go. Seamlessly carry your digital personality with you, ensuring you always make a lasting impression ensuring you always make a lasting impression.</h6>

                <div class="btn1 pt-4 mt-2 p-0">
                  <button type="button" class="btn btn-lg" style={{ background: '#132b43' }}><NavLink to='/card' className='navlink_' style={{ textDecoration: 'none', color: 'white' }} > Get Your Smart Card </NavLink></button>
                </div>
              </div>
            </div>


            <div className="col-lg-6 col-md-6 col-sm-12 "  >

              <img src={img1} alt="here" className="img-thumbnail" style={{ width: '600px', border: 'none', background: 'transparent' }} />

            </div>
          </div>
        </div> 



        {/* <!-- About Start --> */}
      {/* <div class="container Main_About">

          <div className="row Details_About">


            <div className="col-lg-6 col-md-12 col-sm-12 d-flex text-center Content_2" style={{ paddingTop: '2rem' }}>
              
              <img src={newone} alt="here" className="img-fluid" />
              
            </div>


            <div className="col-lg-6 col-md-12 col-sm-12" >
              <div className="first_row content123 mt-4" style={{ marginLeft: '0rem' }}>
                <h6 style={{ fontSize: '18px', fontWeight: '500' }}>welcome to the  CustomCliQ  </h6>
                <h4 style={{ color: '#132b43', paddingTop: '10px' }}><span style={{ color: '#49a0e3' }}> Discover CustomCliQ </span>: Your gateway to smart networking</h4>

                <h6>Make a unique and smart first impression always. Seamlessly create meaningful connections and expand your network with us. Never miss out on potential leads again, as CustomCliQ keeps you connected and informed at every opportunity.</h6>
                <div class="btn12 pt-4 mt-2 p-0 ">
                  <button type="button" class="btn btn-lg" style={{ background: '#132b43' }}><NavLink to='/affliate' className='navlink_' style={{ textDecoration: 'none', color: 'white', fontWeight: '500' }} > Explore CustomCliQ</NavLink></button>
                </div>
              </div>
            </div>


          </div>
        </div> */}
      {/* </div> */}






      {/* -----------------------second content------------------------------------- */}
      {/* 
      <div className="container-fluid" style={{ paddingBottom: '100px' }}>
        <div className="Details_About container ">
          <div className="row">

            <div className="col-lg-6 col-md-12 col-sm-12 pt-5" >
              <div className="first_row content123" style={{ marginLeft: '3rem' }}>
                <h4>Why <span style={{ color: '#49a0e3' }}> CustomCliQ?? </span> </h4>


                <h6>Make a unique and smart first impression always. Seamlessly create meaningful connections and expand your network with ease. Never miss out on potential leads again, as TapOnn keeps you connected and informed at every opportunity.</h6>
                <div class="btn12 pt-4 mt-2 p-0 ">
                  <button type="button" class="btn btn-lg" style={{ background: '#132b43' }}><NavLink to='/affliate' className='navlink_' style={{ textDecoration: 'none', color: 'white', fontWeight: '500' }} > Explore CustomCliQ</NavLink></button>
                </div>
              </div>
            </div>


            <div className="col-lg-6 col-md-12 col-sm-12" >
              <div className="second_row ">
                <img src={img2} alt="i-here" className="d-block img-thumbnail" style={{ border: 'none', background: 'transparent', width: '100%' }} />
              </div>
            </div>
          </div>
        </div>
      </div> */}



      {/* ---------------------third content----------------------------- */}


      {/* <div className="container-fluid" style={{ paddingTop: '0px', paddingBottom: '90px' }}>
        <div className="Details_About container pt-5 mt-5" >
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 mt-3">
              <div className="first_row about_content mt-4">
                <h4>Why <span style={{ color: '#49a0e3' }}> CustomCliQ?? </span> </h4>


                <h6 className="pe-2">Create a custom digital profile that reflects your personal brand, providing the fastest and safest way to share your information. Seamlessly share anything from business details, catalogues, and brochures to videos and meeting links. Connect on the go and instantly collect leads with our innovative features.</h6>

                <div class="btn1 pt-4 mt-2 p-0">
                  <button type="button" class="btn btn-lg" style={{ background: '#132b43' }}><NavLink to='/works' className='navlink_' style={{ textDecoration: 'none', color: 'white' }} > Watch video</NavLink></button>                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12" >
              <div className="second_row mt-2">
                <img src={img3} alt="i-here" className="img-thumbnail" style={{ border: 'none', background: 'transparent', width: '100%' }} />
              </div>
            </div>
          </div>
        </div>
      </div> */}



      {/* ---------------------------marquee logos------------------------------ */}


      <div className="img_logo pt-5" style={{background:'black'}}>

        <h2 className="gradiant pt-4 mt-5 fw-bold" style={{ color: '#49a0e3' }} >Teams and professionals around the <br /> world trust <span style={{ color: '#49a0e3' }} >CustomCliQ</span> </h2>

        <div className="marquee-container " style={{ paddingTop: '5rem', paddingBottom: '7rem' }}>
          <div className="marquee-content" style={{ animationPlayState: isHovered ? 'paused' : 'running' }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)} >
            {/* Original Set */}

            <img src={logo1} alt="i-here" className="img-thumbnail" style={{ border: 'none' }} />
            <img src={logo2} alt="i-here" className="img-thumbnail" style={{ border: 'none' }} />
            <img src={logo3} alt="i-here" className="img-thumbnail" style={{ border: 'none' }} />
            <img src={logo4} alt="i-here" className="img-thumbnail" style={{ border: 'none' }} />
            <img src={logo5} alt="i-here" className="img-thumbnail" style={{ border: 'none' }} />
            <img src={logo6} alt="i-here" className="img-thumbnail" style={{ border: 'none' }} />
            <img src={logo7} alt="i-here" className="img-thumbnail" style={{ border: 'none' }} />
            <img src={logo8} alt="i-here" className="img-thumbnail" style={{ border: 'none' }} />

            {/* Repeated Set to create loop */}

            <img src={logo1} alt="i-here" className="img-thumbnail" style={{ border: 'none' }} />
            <img src={logo2} alt="i-here" className="img-thumbnail" style={{ border: 'none' }} />
            <img src={logo3} alt="i-here" className="img-thumbnail" style={{ border: 'none' }} />
            <img src={logo4} alt="i-here" className="img-thumbnail" style={{ border: 'none' }} />
            <img src={logo5} alt="i-here" className="img-thumbnail" style={{ border: 'none' }} />
            <img src={logo6} alt="i-here" className="img-thumbnail" style={{ border: 'none' }} />
            <img src={logo7} alt="i-here" className="img-thumbnail" style={{ border: 'none' }} />
            <img src={logo8} alt="i-here" className="img-thumbnail" style={{ border: 'none' }} />
          </div>
        </div>
      </div>


      {/*------------------------- cards--------------------------------- */}

      {/* <div className="container-fluid main_cards pb-5 pt-5" >

        <div className="card_ container">
          <h2>Multiple Options <span style={{ color: '#49a0e3' }}> Available </span></h2>
          <h4>For All Professions</h4>

          <div className="row" style={{ paddingBottom: '70px' }}>

           
            <div className="col-lg-6 col-md-6 col-sm-12 mt-5 ">
              <div className="container cnet1 ps-1 p-4 shadow-lg main_p" style={{ borderRadius: '10px' }}>
                <div className="d-flex p-4 flex-column flex-md-row">
                  <div className="col-12 col-md-7 content pt-2">
                    <h5 style={{ fontWeight: 700, fontSize: '22px' }}>Metal NFC Cards</h5>

                    <p className="pe-2" style={{ fontSize: '17px' }}>
                      Make connection with these high-quality NFC visiting card and create a statement that’s sure to impress!
                    </p>
                    <button className="btn btn-md text-white" style={{ background: '#132b43', fontSize: '18px' }}>
                      Shop Now
                    </button>
                  </div>

                  <div className="col-12 col-md-5 images mt-4 mt-md-0 ms-md-4">
                    <img src={first1} alt="image" className="img-fluid" style={{ borderRadius: '10px' }} />
                  </div>

                  <div className="go-corner" >
                    <div className="go-arrow">
                      →
                    </div>
                  </div>
                </div>
              </div>
            </div>


           

            <div className="col-lg-6 col-md-6 col-sm-12 mt-5">
              <div className="container cnet2 ps-1 p-4 shadow-lg main_p2" style={{ borderRadius: '10px' }}>
                <div className="d-flex p-4 flex-column flex-md-row">
                  <div className="col-12 col-md-7 content pt-2">
                    <h5 style={{ fontWeight: 700, fontSize: '22px' }}>PVC NFC Cards</h5>

                    <p className="pe-2" style={{ fontSize: '17px' }}>
                      PVC NFC cards by CustomCliQ is the perfect solution for a cheaper but durable business visiting card.
                    </p>
                    <button className="btn btn-md btn2 text-white" style={{ background: '#49a0e3', fontSize: '18px' }} >Shop Now</button>
                  </div>

                  <div className="col-12 col-md-5 images mt-4 mt-md-0 ms-md-4">
                    <img src={first2} alt="image" className="img-fluid" style={{ borderRadius: '10px' }} />
                  </div>

                  <div className="go-corner2" >
                    <div className="go-arrow2">
                      →
                    </div>
                  </div>
                </div>
              </div>
            </div>


          
            <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
              <div className="container cnet2 ps-1 p-4 shadow-lg main_p2" style={{ borderRadius: '10px' }}>
                <div className="d-flex p-4 flex-column flex-md-row">
                  <div className="col-12 col-md-7 content pt-2">
                    <h5 style={{ fontWeight: 700, fontSize: '22px' }}>Influencer NFC Card</h5>

                    <p className="pe-2" style={{ fontSize: '17px' }}>
                      These smart business cards are made just for influencers to network with a dash of style!
                    </p>
                    <button className="btn btn-md btn2 text-white" style={{ background: '#49a0e3', fontSize: '18px' }} >Shop Now</button>
                  </div>

                  <div className="col-12 col-md-5 images mt-4 mt-md-0 ms-md-4">
                    <img src={first3} alt="image" className="img-fluid" style={{ borderRadius: '10px' }} />
                  </div>
                  <div className="go-corner2" >
                    <div className="go-arrow2">
                      →
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
              <div className="container cnet1 ps-1 p-4 shadow-lg main_p" style={{ borderRadius: '10px' }}>
                <div className="d-flex p-4 flex-column flex-md-row">
                  <div className="col-12 col-md-7 content pt-2">
                    <h5 style={{ fontWeight: 700, fontSize: '22px' }}>Engraved NFC Cards</h5>

                    <p className="pe-2" style={{ fontSize: '17px' }}>
                      Each engraved NFC based business card is a masterpiece, carefully engraved to reflect your style!
                    </p>
                    <button className="btn btn-md text-white" style={{ background: '#132b43', fontSize: '18px' }}>
                      Shop Now
                    </button>
                  </div>

                  <div className="col-12 col-md-5 images mt-4 mt-md-0 ms-md-4">
                    <img src={first4} alt="image" className="img-fluid" style={{ borderRadius: '10px' }} />
                  </div>
                  <div className="go-corner" >
                    <div className="go-arrow">
                      →
                    </div>
                  </div>
                </div>
              </div>
            </div>




          </div>
        </div>
      </div> */}





      {/* -----------Grow your network part-------- */}
      <div class="container-fluid Main_About bg-black">

        <div className="row container Details_About">
          <div className="col-lg-6 col-md-12 col-sm-12 " style={{ paddingTop: "5rem" }} >
            <div className="first_row content123 mt-4" style={{ marginLeft: '0rem' }}>
              <h4 style={{ color: '#49a0e3', paddingTop: '10px' }}> Grow your network wherever you go </h4>
              <h6>Elevate your professional image with a compelling online presence that boosts your credibility wherever you go. Seamlessly carry your digital personality with you, ensuring you always make a lasting impression. Elevate your professional image with a compelling online presence that boosts your credibility wherever you go. Seamlessly carry your digital personality with you, ensuring you always make a lasting impression.</h6>
              <div class="btn12 pt-4 mt-2 p-0 ">
                <button type="button" class="btn btn-lg" style={{ background: '#49a0e3' }}><NavLink to='#' className='navlink_' style={{ textDecoration: 'none', color: 'black', fontWeight: '500' }} > Get Your Smart Card</NavLink></button>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12 pt-4 ">
            {/* <img src={newone} alt="here" className="img-fluid" /> */}
            <div className="video-container d-flex justify-content-center" >
              <video autoPlay loop muted className="" style={{ width: '80%', height: '500px' }} >
                <source src="https://taponn.digital/cdn/shop/videos/c/vp/33c1013bca4d469d9c30b30278d3fbbe/33c1013bca4d469d9c30b30278d3fbbe.HD-1080p-7.2Mbps-31574842.mp4?v=0" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>



        <div className="row container Details_About pt-4 pb-5">

          <div className="col-lg-6 col-md-12 col-sm-12 pt-5">
            {/* <img src={newone} alt="here" className="img-fluid" /> */}
            <div className="video-container d-flex justify-content-center" >
              <video autoPlay loop muted  style={{ width: '85%', height: '500px' }} >
                <source src="https://taponn.digital/cdn/shop/videos/c/vp/111de6cd54c44bffb924425a9a1cef1b/111de6cd54c44bffb924425a9a1cef1b.HD-1080p-7.2Mbps-31575193.mp4?v=0%22" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12 " style={{ paddingTop: "5rem" }} >
            <div className="first_row content123 mt-4" style={{ marginLeft: '0rem' }}>
              <h4 style={{ color: '#49a0e3', paddingTop: '10px' }}> Discover CustomCliQ : Your gateway to smart networking </h4>
              <h6 style={{paddingRight:'2rem'}}>Make a unique and smart first impression always. Seamlessly create meaningful connections and expand your network with ease. Never miss out on potential leads again, as customcliq keeps you connected and informed at every opportunity.Elevate your professional image with a compelling online presence that boosts your credibility wherever you go. Seamlessly carry your digital personality with you, ensuring you always make a lasting impression.</h6>
              <div class="btn12 pt-4 mt-2 p-0 ">
                <button type="button" class="btn btn-lg" style={{ background: '#49a0e3' }}><NavLink to='/affliate' className='navlink_' style={{ textDecoration: 'none', color: 'black', fontWeight: '500' }} > Explore CustomCliQ</NavLink></button>
              </div>
            </div>
          </div>
        </div>


        <div className="row container Details_About">
          <div className="col-lg-6 col-md-12 col-sm-12 " style={{ paddingTop: "5rem" }} >
            <div className="first_row content123 mt-4" style={{ marginLeft: '0rem' }}>
              <h4 style={{ color: '#49a0e3', paddingTop: '10px' }}> Smart products : Elevate your presence </h4>
              <h6>Introducing CustomCliQ's smart products—your key to sharing who you are and what you do in the most customized, impressive, and fastest way possible. Backed by NFC and QR code technology and perfected by our expert team, CustomCliQ's smart products redefine the art of self-presentation. Seamlessly carry your digital personality with you, ensuring you always make a lasting impression.</h6>
              <div class="btn12 pt-4 mt-2 p-0 ">
                <button type="button" class="btn btn-lg" style={{ background: '#49a0e3' }}><NavLink to='/affliate' className='navlink_' style={{ textDecoration: 'none', color: 'black', fontWeight: '500' }} > Explore CustomCliQ</NavLink></button>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12 pt-4 ">
            {/* <img src={newone} alt="here" className="img-fluid" /> */}
            <div className="video-container d-flex justify-content-center" >
              <video autoPlay loop muted className="" style={{ width: '70%', height: '500px' }} >
                <source src="https://taponn.digital/cdn/shop/videos/c/vp/d97eb8f8b5c74ac8aa991ef186a88141/d97eb8f8b5c74ac8aa991ef186a88141.HD-1080p-2.5Mbps-31578361.mp4?v=0%22%20type=%22video/mp4%22" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>

      </div>


      {/* so many way to share----------- */}

       <div className="container-fluid wayto_share bg-black" style={{paddingTop:'6rem',paddingBottom:'5rem'}}>
        <div className="container">
          <h4>So Many Ways To Share</h4>

          <div className="image pt-5 mt-5">
             <img src={ShareImg} alt="here-i" className="d-block img-fluid" />
          </div>
        </div>
       </div>



       {/* Eco-friendly part------- */}
       <div className="container-fluid bg_eco" style={{paddingTop:'6rem'}}>
        <div className="container">
        <div className="row container Details_About" style={{paddingBottom:'4rem'}}>
          <div className="col-lg-6 col-md-12 col-sm-12 " style={{ paddingTop: "5rem" }} >
            <div className="first_row content123 mt-4 pe-3 ps-3">
              <h4 style={{ color: '#49a0e3', paddingTop: '10px' }}> We saved 70000+ Trees </h4>
              <h6 className="fw-bold pt-4" style={{fontSize:'1.3rem'}}>At CustomCliQ, we revolutionize professional networking with time-saving, eco-friendly technology.</h6>

              <h6 style={{fontSize:'1.3rem'}}>For every <b>1000</b>  Paper business cards approx <b>9</b> trees are cut down. The choice is yours. To cut down or grow Trees.</h6>
              {/* <div class="btn12 pt-4 mt-2 p-0 ">
                <button type="button" class="btn btn-lg" style={{ background: '#49a0e3' }}><NavLink to='#' className='navlink_' style={{ textDecoration: 'none', color: 'black', fontWeight: '500' }} > Get Your Smart Card</NavLink></button>
              </div> */}
            </div>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12 pt-4 d-flex justify-content-center ">
            <img src={SaveLife} alt="here" className=" img-fluid" style={{width:'80%'}} />
            
          </div>
        </div>
        </div>
       </div>

      {/*-------------------------------Accordian-------------------------- */}

      {/* <div className="container-fluid" style={{ paddingBottom: '80px', background: '#132b43' }}>
        <div className="container pt-5" >


          <div className="row main_accordian">
            <div className="col-lg-6 col-md-12 col-sm-12 about_accordian text-white pt-4 mt-4 mb-5 ps-4" >
              <h4 style={{ color: '#49a0e3' }}>FAQ'S</h4>

              <h5>Need help? We go you covered!</h5>

              <h6>So if you're feel stuck, confused, or just need some extra help, <br /> don't hesitate to reach out at</h6>

              <p style={{ fontWeight: '500', fontSize: '20px', paddingTop: '10px' }}>customcliq.com</p>
            </div>

            <div className="col-lg-6  col-md-12 col-sm-12 pt-5" >
              <div class="accordion second_acc accordion-flush" id="accordionFlushExample">
                <div class="accordion-item" >
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" style={{ background: "#49a0e3", fontWeight: '500', fontSize: '18px', color: 'white' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                      What is CustomCliQ??
                    </button>
                  </h2>
                  <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body"> CustomCliQ is the smart networking platform for all professionals. It will help you grow your network with its smart products based on NFC technology & strong app technology to create & share digital profile in the most fastest & convinenent way posiibble. It offers a range of personalized products for individuals and teams from every field.
                      If you don't prefer sharing your personal details with your professional connections, you can easily create Multiple profiles on the TapOnn app.
                      All TapOnn products comes with 2 Year manufacturing warrantly & 90 days satisafaction policy & guess what? By choosing CustomCliQ, you choose the Planet.</div>
                  </div>
                </div>

                
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="text-white accordion-button collapsed" style={{ background: "#49a0e3", fontWeight: '500', fontSize: '18px', fontSize: '19px', color: 'white' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                      How does it works??
                    </button>
                  </h2>
                  <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">CustomCliQ products operate with the help of a smart NFC chip that wirelessly shares your digital profile with a NFC-enabled phone. The receiver’s phone shows a pop notification when your TapOnn smart product is tapped on their phone. The notification leads them to your digital profile containing all the information you have added which can be saved with a single click. Incase your phone doesn't have NFC, all our smart product are embedded with a dynamic QR code that shares your information with a simple scan. Rest assured, all of this is completely safe & Secure.</div>
                  </div>
                </div>

                
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" style={{ background: "#49a0e3", fontWeight: '500', fontSize: '18px', color: 'white' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                      What is 90-Day Satisafaction Policy
                    </button>
                  </h2>
                  <div id="flush-collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">At CustomCliQ, we're committed to ensuring your complete satisfaction with our smart product. That's why we offer a comprehensive 90-day policy that allows you to experience the full potential of efficient networking without any risk.

                      How It Works:
                      From the day of your CustomCliQ smart product purchase, you have a 90-day window to explore seamless networking possibilities. If, after this period i.e ( between 90 - 120 days ) you find that our product doesn't meet your expectations, we've got you covered.

                      Our Promise:
                      In the event that you're not entirely satisfied with your CustomCliQ smart product after 90 days , you can reach out to us at . Our dedicated team will process your request promptly, and we will refund the entire amount of your purchase.
                    </div>
                  </div>
                </div>

                

                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" style={{ background: "#49a0e3", fontWeight: '500', fontSize: '18px', color: 'white' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                      How to share if receiver’s phone doesn't have NFC??
                    </button>
                  </h2>
                  <div id="flush-collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      If you are worried that your network will be restricted to only the people who own a compatible phone, fear not. All TapOnn smart products also comes with a QR card. Your potential connection can scan this QR with any phone and it'll lead them to your profile within seconds.
                    </div>
                  </div>
                </div>


                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed" style={{ background: "#49a0e3", fontWeight: '500', fontSize: '18px', color: 'white' }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                      How to Activate without NFC??
                    </button>
                  </h2>
                  <div id="flush-collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                      With CustomCliQ's latest NON-NFC activation feature, all you need is to click on Activate device  Activate QR  & scan the QR code. that's it your smart product will automatically get activated.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}



      {/*------------------------ customer review -----------------------*/}
      <Customer_review />



     {/* FAQ in home page---------- */}
     <div className='box_content main_affliate bg-black'  style={{ paddingBottom: '8rem',paddingTop:'2rem' }}>
        <h4 style={{ color: '#49a0e3', paddingBottom: '2rem' }} >Frequently Asked Questions</h4>

        <div className='about_content2'>

          {/* accordian */}

          <div className="accordion ac2" id="accordionExample">
            <div className="accordion-item" >
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne" style={{  fontWeight: '600', fontSize: '19px' }}
                >
                  1.) What is a CustomCliQ Business Card?
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample" style={{fontSize:'18px'}}
              >
                <div className="accordion-body">

                  The CustomCliQ Business Card, embedded with an NFC chip, allows you to share and save your contact details by tapping it on smartphones. For non-NFC compatible phones, it also includes a QR code.
                </div>
              </div>
            </div>


            {/* second */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo" style={{fontWeight: '600', fontSize: '19px' }}
                >
                  2.) Tell us how a Scube Card works?
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample" style={{fontSize:'18px'}}
              >
                <div className="accordion-body">
                Tap, Share, and Connect! It's that simple with the Scube Business Card. NFC technology enables quick communication with a smartphone through a tap, and for non-NFC compatible phones, a QR Code is also provided for easy sharing.
                </div>
              </div>
            </div>



            {/* third */}
            <div className="accordion-item ">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree" style={{ fontWeight: '600', fontSize: '19px' }}
                >
                  3.) Does Scube Card even work on Android mobiles?
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample" style={{fontSize:'18px'}}
              >
                <div className="accordion-body">
                Yes of course! Ask the receiver to turn on NFC in their android phone and tap the Scube Card on the mobile.
                </div>
              </div>
            </div>


            {/* four */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFour">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour" style={{fontWeight: '600', fontSize: '19px' }}
                >
                  4.) Does Scube Card work on iPhones?
                </button>
              </h2>
              <div
                id="collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample" style={{fontSize:'18px'}}
              >
                <div className="accordion-body">
                Yes, it does work on iPhones as well. iPhones with iOS 13 or later.
                </div>
              </div>
            </div>


            {/* five */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingfive">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="false"
                  aria-controls="collapsefive" style={{ fontWeight: '600', fontSize: '19px' }}
                >
                  5.) Should my client install a Scube app to receive my contact information?
                </button>
              </h2>
              <div
                id="collapsefive"
                className="accordion-collapse collapse"
                aria-labelledby="headingfive"
                data-bs-parent="#accordionExample" style={{fontSize:'18px'}}
              >
                <div className="accordion-body">
                Nah, No app needed! Scube app is not required to receive the information. Only the card user needs the app to link and update card details.
                </div>
              </div>
            </div>


            {/* six */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingsix">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsesix"
                  aria-expanded="false"
                  aria-controls="collapsesix" style={{  fontWeight: '600', fontSize: '19px' }}
                >
                  6.) Why do I need the Scube App?
                </button>
              </h2>
              <div
                id="collapsesix"
                className="accordion-collapse collapse"
                aria-labelledby="headingsix"
                data-bs-parent="#accordionExample" style={{fontSize:'18px'}}
              >
                <div className="accordion-body">
                Scube App is used to create profiles and update your information in the card. You can always edit the details using the Scube App.
                </div>
              </div>
            </div>

            {/* seven */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingseven">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseseven"
                  aria-expanded="false"
                  aria-controls="collapseseven" style={{fontWeight: '600', fontSize: '19px' }}
                >
                  7.) How to share details with non-NFC smartphones?
                </button>
              </h2>
              <div
                id="collapseseven"
                className="accordion-collapse collapse"
                aria-labelledby="headingseven"
                data-bs-parent="#accordionExample" style={{fontSize:'18px'}}
              >
                <div className="accordion-body">
                QR code! That's easy! You can easily share your details by scanning the QR code provided on your Scube card.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*----------------- card ----------------------- */}
      {/* <Cards /> */}


      {/* footer */}
      <Footer />
    </>
  )
}


export default Home;
